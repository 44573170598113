import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast, getAccessToken, getIncserveToken } from '../../utils/helper';
import {
    GET_PRODUCT_LIST,
    GET_PLANS_AND_PRICING,
    UPDATE_PAYMENT_INFORMATION,
    GET_ORDER_LIST,
    GET_BILLING_INFORMATION,
} from '../action/types';
import {
    getProductListSuccess,
    getProductListFailure,
    getPlansAndPricingListSuccess,
    getPlansAndPricingListFailure,
    updatePaymentInformationSuccess,
    updatePaymentInformationFailure,
    getOrderListSuccess,
    getOrderListFailure,
    getBillingInformationSuccess,
    getBillingInformationFailure,
} from '../action';
import PRODUCT from '../../utils/api/product';
import PLANS from '../../utils/api/plans';
import ORDERS from '../../utils/api/orders';

function* getProductListRequest() {
    try {
        let res;
        const header = { headers: { Authorization: `Bearer ${getAccessToken}` } };
        if (getIncserveToken) {
            res = yield PRODUCT.post(`products/productList`, { incserveToken: getIncserveToken }, header);
        } else {
            res = yield PRODUCT.get(`products/productList`, header);
        }
        if (res.status === 200 || res.status === 201) {
            yield put(getProductListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getProductListFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getPlansAndPricingListRequest(action) {
    try {
        const res = yield PLANS.get(`plans/planList/${action.payload}`);
        if (res.status === 200) {
            yield put(getPlansAndPricingListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPlansAndPricingListFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* updatePaymentInformationRequest(action) {
    try {
        let dataSet = action.payload.data;
        if (!dataSet?.billingDetails?.gst) {
            delete dataSet?.billingDetails?.gst;
        }
        const res = yield ORDERS.post(`orders`, action.payload.data);
        if (res.status === 201) {
            yield put(updatePaymentInformationSuccess());
            if (action.payload.data?.isFreePlan) {
                yield call(action.payload.callback());
                return;
            }
            const options = {
                name: res.data?.result?.razorPayPayload?.name,
                amount: res.data?.result?.razorPayPayload?.amount * 100,
                description: res.data?.result?.razorPayPayload?.description,
                currency: res.data?.result?.razorPayPayload?.currency,
                key: res.data?.result?.razorPayPayload?.key,
                prefill: res.data?.result?.razorPayPayload?.prefill,
                config: {
                    display: {
                        blocks: {
                            card: {
                                name: 'Pay using Card',
                                instruments: [
                                    {
                                        method: 'card',
                                        types: ['credit', 'debit'],
                                    },
                                ],
                            },
                        },
                        sequence: ['block.card', 'block.other'],
                        preferences: {
                            show_default_blocks: false,
                        },
                    },
                },
                image: 'https://incruiter-media.s3.ap-south-1.amazonaws.com/client1/emailTemplates/file-1661232215610-280733994.png',
                subscription_id: res.data?.result?.order.razorpaySubscriptionId,
                handler: function (response) {
                    ORDERS.put(
                        `orders/${res.data?.result?.order?._id}/payment-response`,
                        {
                            successPayload: {
                                paymentId: response.razorpay_payment_id,
                                signature: response.razorpay_signature,
                                subscriptionId: response.razorpay_subscription_id,
                            },
                            isSuccess: true,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${getAccessToken}`,
                            },
                        }
                    );
                    action.payload.callback();
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {
                ORDERS.put(`orders/${res.data.result.orderId}/payment-response`, {
                    errorPayload: {
                        code: response?.error?.code,
                        description: response?.error?.description,
                        source: response?.error?.source,
                        step: response?.error?.step,
                        reason: response?.error?.reason,
                    },
                    metadata: response?.error?.metadata,
                    isSuccess: false,
                });
            });
            paymentObject.open();
        }
    } catch (e) {
        yield put(updatePaymentInformationFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getOrderListRequest(action) {
    const isPageAndPageSize =
        action.payload.page && action.payload.pageSize
            ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
            : '';
    const isSort =
        action.payload.sort && action.payload.order ? `&sort=${action.payload.sort}&order=${action.payload.order}` : '';
    try {
        const res = yield ORDERS.get(`orders${isPageAndPageSize}${isSort}`);
        if (res.status === 200) {
            yield put(getOrderListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getOrderListFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getBillingInformationRequest(action) {
    const planId = action.payload.planId;
    const subscriptionBasis = action.payload.subscriptionBasis;
    try {
        const res = yield PLANS.get(
            `plans/billing-information?planId=${planId}&subscriptionBasis=${subscriptionBasis}`
        );
        if (res.status === 200) {
            yield put(getBillingInformationSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getBillingInformationFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

export function* watchGetProductListAPI() {
    yield takeEvery(GET_PRODUCT_LIST, getProductListRequest);
}

export function* watchGetPlansAndPricingListAPI() {
    yield takeEvery(GET_PLANS_AND_PRICING, getPlansAndPricingListRequest);
}

export function* watchUpdatePaymentInformationAPI() {
    yield takeEvery(UPDATE_PAYMENT_INFORMATION, updatePaymentInformationRequest);
}

export function* watchGetOrderListAPI() {
    yield takeEvery(GET_ORDER_LIST, getOrderListRequest);
}

export function* watchGetBillingInformation() {
    yield takeEvery(GET_BILLING_INFORMATION, getBillingInformationRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetProductListAPI(),
        watchGetPlansAndPricingListAPI(),
        watchUpdatePaymentInformationAPI(),
        watchGetOrderListAPI(),
        watchGetBillingInformation(),
    ]);
}
