import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast, getAccessToken } from '../../utils/helper';

import {
    GET_INTERVIEW_MONTHLY_STATS,
    GET_SUBUSER_INTERVIEW_MONTHLY_STATS,
    GET_EVENT_LIST,
    GET_NOTIFICATION_LIST,
    READ_NOTIFICATION_LIST,
    CLEAR_NOTIFICATION_LIST,
    GET_PANELIST_ANALYTICS,
    GET_NOTIFICATION_SETTINGS,
    ADD_DELETE_NOTIFICATION_SETTINGS,
    GET_PANELIST_INTERVIEW_STATS,
    GET_DASHBOARD_POSITION_LIST,
    GET_CANDIDATE_STAT,
} from '../action/types';
import {
    getPanelistAnalyticsSuccess,
    getPanelistAnalyticsFailure,
    getInterviewMonthlyStatsSuccess,
    getInterviewMonthlyStatsFailure,
    getEventListSuccess,
    getEventListFailure,
    getNotificationSuccess,
    getNotificationFailure,
    readNotificationSuccess,
    readNotificationFailure,
    clearNotificationSuccess,
    clearNotificationFailure,
    getNotificationSettingsSuccess,
    getNotificationSettingsFailure,
    addDeleteNotificationSettingsSuccess,
    addDeleteNotificationSettingsFailure,
    getPanelistInterviewStatsSuccess,
    getPanelistInterviewStatsFailure,
    getSubUserInterviewMonthlyStatsSuccess,
    getSubUserInterviewMonthlyStatsFailure,
    getDashboardPositionListSuccess,
    getDashboardPositionListFailure,
    getCandidateStatSuccess,
    getCandidateStatFailure,
} from '../action';
import INTERVIEW from '../../utils/api/interview';
import USERS from '../../utils/api/users';
import Auth from '../../utils/api/auth';
import PERMISSION from '../../utils/api/permission';

function* getPanelistInterviewStatsRequest(action) {
    const id = action?.payload?.productId;
    let data = {
        from: action?.payload?.data?.timeRange?.from,
        to: action?.payload?.data?.timeRange?.to,
    };
    if (action?.payload?.data?.panelistId && action?.payload?.data?.panelistId !== 'all') {
        data = {
            ...data,
            panelist: action?.payload?.data?.panelistId,
        };
    }
    try {
        const res = yield INTERVIEW.post(`interviews/panelistGraphById/${id}`, data);
        if (res.status === 201) {
            yield put(getPanelistInterviewStatsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPanelistInterviewStatsFailure());
    }
}

function* getCandidateInterviewStatsRequest(action) {
    let data = {
        from: action?.payload?.data?.timeRange?.from,
        to: action?.payload?.data?.timeRange?.to,
        productId: action?.payload?.productId,
    };
    if (action?.payload?.data?.panelistId && action?.payload?.data?.panelistId !== 'all') {
        data = {
            ...data,
            panelist: action?.payload?.data?.panelistId,
        };
    }
    try {
        const res = yield INTERVIEW.post(`interviews/CandidateStats`, data);
        if (res.status === 201) {
            yield put(getCandidateStatSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getCandidateStatFailure());
    }
}

function* addDeleteNotificationSettingsRequest(action) {
    try {
        const permissions = {
            permissions: action?.payload?.data?.permissions ? action?.payload?.data?.permissions : [],
        };
        const res = yield Auth.put(`/employers/eventConstants`, permissions);
        if (res.status === 200) {
            yield put(addDeleteNotificationSettingsSuccess());
            yield call(action.payload.data.callback());
        }
    } catch (e) {
        yield put(addDeleteNotificationSettingsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getNotificationSettingsRequest() {
    try {
        const res = yield Auth.get(`/employers/eventConstants`);
        if (res.status === 200) {
            yield put(getNotificationSettingsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getNotificationSettingsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getPanelistAnalyticsRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviews/panelistAnalytics/${action.payload.productId}`);
        if (res.status === 200) {
            yield put(getPanelistAnalyticsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPanelistAnalyticsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getInterviewMonthlyStatsRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviews/interview-stats-monthwise?productId=${action.payload.productId}`);
        if (res.status === 200) {
            yield put(getInterviewMonthlyStatsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewMonthlyStatsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getSubUserInterviewMonthlyStatsRequest(action) {
    const isUser =
        action?.payload?.interviewStatusFilter?.userId && action?.payload?.interviewStatusFilter?.userId !== 'all'
            ? `&createdBy=${action.payload?.interviewStatusFilter?.userId}`
            : '';

    const date = new Date();
    const year = date.toISOString().split('-')[0];
    const isMonth = action?.payload?.interviewStatusFilter?.month
        ? `${action.payload.interviewStatusFilter?.month}`
        : `&from=${year}-01-01T00:00:00.000Z&to=${year}-01-31T00:00:00.000Z`;
    try {
        const res = yield INTERVIEW.get(
            `interviews/interview-stats-monthwise?productId=${action.payload.productId}${isUser}${isMonth}`
        );
        if (res.status === 200) {
            yield put(getSubUserInterviewMonthlyStatsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getSubUserInterviewMonthlyStatsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getEventListRequest(action) {
    const date = action.payload.date ? `?date=${action.payload.date}` : '';
    try {
        const res = yield INTERVIEW.get(`interviews/scheduled-list/${action.payload.productId}${date}`);
        if (res.status === 201 || res.status === 200) {
            yield put(getEventListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getEventListFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getNotificationRequest(action) {
    try {
        const isPageAndPageSize =
            action.payload.page && action.payload.pageSize
                ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
                : '';
        const isSearch = action.payload.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';
        const res = yield PERMISSION.get(`users/notification${isPageAndPageSize}${isSearch}`, {
            headers: {
                Authorization: `Bearer ${getAccessToken}`,
            },
        });
        if (res.status === 200) {
            yield put(getNotificationSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getNotificationFailure());
    }
}

function* readNotificationRequest(action) {
    try {
        const res = yield USERS.put(`users/notification/readAll`);
        if (res.status === 200) {
            yield put(readNotificationSuccess());
            yield call(action.payload.callback());
        }
    } catch (e) {
        yield put(readNotificationFailure());
    }
}

function* clearNotificationRequest(action) {
    try {
        const isNotificationId = action.payload.id ? `?id=${action.payload.id}` : '';
        const res = yield USERS.delete(`users/notification${isNotificationId}`);
        if (res.status === 204) {
            yield put(clearNotificationSuccess());
            yield call(action.payload.callback());
        }
    } catch (e) {
        yield put(clearNotificationFailure());
    }
}

function* getDashboardPositionListRequest(action) {
    try {
        let productId = action?.payload?.productId;
        const res = yield INTERVIEW.get(`positions/dashboard/dropdown?product=${productId}`);
        if (res.status === 200) {
            yield put(getDashboardPositionListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getDashboardPositionListFailure());
    }
}

export function* watchGetDashboardPositionListAPI() {
    yield takeEvery(GET_DASHBOARD_POSITION_LIST, getDashboardPositionListRequest);
}

export function* watchGetPanelistInterviewStatsAPI() {
    yield takeEvery(GET_PANELIST_INTERVIEW_STATS, getPanelistInterviewStatsRequest);
}
export function* watchGetCandidateInterviewStatsAPI() {
    yield takeEvery(GET_CANDIDATE_STAT, getCandidateInterviewStatsRequest);
}
export function* watchaddDeleteNotificationSettingsAPI() {
    yield takeEvery(ADD_DELETE_NOTIFICATION_SETTINGS, addDeleteNotificationSettingsRequest);
}

export function* watchGetNotificationSettingsAPI() {
    yield takeEvery(GET_NOTIFICATION_SETTINGS, getNotificationSettingsRequest);
}

export function* watchGetPanelistAnalyticsAPI() {
    yield takeEvery(GET_PANELIST_ANALYTICS, getPanelistAnalyticsRequest);
}
export function* watchGetSubUserInterviewMonthlyStatsAPI() {
    yield takeEvery(GET_SUBUSER_INTERVIEW_MONTHLY_STATS, getSubUserInterviewMonthlyStatsRequest);
}
export function* watchGetInterviewMonthlyStatsAPI() {
    yield takeEvery(GET_INTERVIEW_MONTHLY_STATS, getInterviewMonthlyStatsRequest);
}
export function* watchGetEventListAPI() {
    yield takeEvery(GET_EVENT_LIST, getEventListRequest);
}
export function* watchGetNotificationAPI() {
    yield takeEvery(GET_NOTIFICATION_LIST, getNotificationRequest);
}
export function* watchReadNotificationAPI() {
    yield takeEvery(READ_NOTIFICATION_LIST, readNotificationRequest);
}
export function* watchClearNotificationAPI() {
    yield takeEvery(CLEAR_NOTIFICATION_LIST, clearNotificationRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetPanelistAnalyticsAPI(),
        watchGetInterviewMonthlyStatsAPI(),
        watchGetSubUserInterviewMonthlyStatsAPI(),
        watchGetEventListAPI(),
        watchGetNotificationAPI(),
        watchReadNotificationAPI(),
        watchClearNotificationAPI(),
        watchGetNotificationSettingsAPI(),
        watchaddDeleteNotificationSettingsAPI(),
        watchGetPanelistInterviewStatsAPI(),
        watchGetDashboardPositionListAPI(),
        watchGetCandidateInterviewStatsAPI(),
    ]);
}
