import {
    POST_INTERVIEW,
    POST_INTERVIEW_SUCCESS,
    POST_INTERVIEW_FAILURE,
    POST_BULK_INTERVIEW,
    POST_BULK_INTERVIEW_SUCCESS,
    POST_BULK_INTERVIEW_FAILURE,
    GET_INTERVIEW,
    GET_INTERVIEW_SUCCESS,
    GET_INTERVIEW_FAILURE,
    GET_INTERVIEW_STAT,
    GET_INTERVIEW_STAT_SUCCESS,
    GET_INTERVIEW_STAT_FAILURE,
    GET_POSITION_STAT,
    GET_POSITION_STAT_SUCCESS,
    GET_POSITION_STAT_FAILURE,
    UPDATE_INTERVIEW_STATUS,
    UPDATE_INTERVIEW_STATUS_SUCCESS,
    UPDATE_INTERVIEW_STATUS_FAILURE,
    GET_FEEDBACK,
    GET_FEEDBACK_SUCCESS,
    GET_FEEDBACK_FAILURE,
    CREATE_CALENDAR_INVITATION,
    CREATE_CALENDAR_INVITATION_SUCCESS,
    CREATE_CALENDAR_INVITATION_FAILURE,
    GET_FEEDBACK_REPORT,
    GET_FEEDBACK_REPORT_SUCCESS,
    GET_FEEDBACK_REPORT_FAILURE,
    GET_INTERVIEW_BY_ID,
    GET_INTERVIEW_BY_ID_SUCCESS,
    GET_INTERVIEW_BY_ID_FOR_POSITION,
    GET_INTERVIEW_BY_ID_FOR_POSITION_SUCCESS,
    GET_INTERVIEW_BY_ID_FOR_POSITION_FAILURE,
    GET_INDUSTRY_TYPE_FAILURE,
    CHECK_CANDIDATE_EXISTS,
    CHECK_CANDIDATE_EXISTS_SUCCESS,
    CHECK_CANDIDATE_EXISTS_FAILURE,
    GET_PANELIST_CALENDAR_REQUEST,
    GET_PANELIST_CALENDAR_REQUEST_SUCCESS,
    GET_PANELIST_CALENDAR_REQUEST_FAILURE,
    GET_CANDIDATE_CALENDAR_REQUEST,
    GET_CANDIDATE_CALENDAR_REQUEST_SUCCESS,
    GET_CANDIDATE_CALENDAR_REQUEST_FAILURE,
    GET_CANDIDATE_DETAILS_USING_RESUME,
    GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS,
    GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE,
    ADD_CUSTOM_FILE,
    ADD_CUSTOM_FILE_SUCCESS,
    ADD_CUSTOM_FILE_FAILURE,
    CREATE_JD_SUCCESS,
    CREATE_JD,
    CREATE_JD_FAILURE,
    REQUEST_FEEDBACK,
    REQUEST_FEEDBACK_SUCCESS,
    REQUEST_FEEDBACK_FAILURE,
    CONTINUE_CALENDAR_INVITATION,
    CONTINUE_CALENDAR_INVITATION_SUCCESS,
    CONTINUE_CALENDAR_INVITATION_FAILURE,
    GET_POSITION_STAT_FOR_BAR_GRAPH,
    GET_POSITION_STAT_FOR_BAR_GRAPH_SUCCESS,
    GET_POSITION_STAT_FOR_BAR_GRAPH_FAILURE,
    GET_CANDIDATE_STAT,
    GET_CANDIDATE_STAT_SUCCESS,
    GET_CANDIDATE_STAT_FAILURE,
    RESEND_EMAIL,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_FAILURE,
    FETCH_RESUME_SCORE,
    FETCH_RESUME_SCORE_SUCCESS,
    FETCH_RESUME_SCORE_FAILURE,
    GET_INTERVIEW_INCBOT,
    GET_INTERVIEW_INCBOT_SUCCESS,
    GET_INTERVIEW_INCBOT_FAILURE,
    GET_INCVID_PLATFORM_PERMISSION,
    GET_INCVID_PLATFORM_PERMISSION_SUCCESS,
    GET_INCVID_PLATFORM_PERMISSION_FAILURE,
    GET_INTERVIEW_DATA,
    GET_INTERVIEW_DATA_SUCCESS,
    GET_INTERVIEW_DATA_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    positionStatForBarGraphLoading: false,
    rescheduleErrorLoading: false,
    interviewList: null,
    feedbackData: null,
    feedbackReportData: null,
    myInterview: null,
    authErrorMessage: null,
    authErrorMessageV2: null,
    myInterviewCalendar: null,
    myInterviewCandidate: null,
    myInterviewPosition: null,
    myCandidate: null,
    checkCandidateExists: null,
    interviewStat: null,
    positionStat: null,
    panelistCalendarData: null,
    timeStamp: null,
    candidateDetails: null,
    bulkUploadData: null,
    customFileDetails: null,
    jDData: null,
    positionStatForBarGraph: null,
    bulkUploadLoading: false,
    candidateStat: null,
    resumeScore: null,
    incbotInterviewInfo: null,
    incVidPermission: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REQUEST_FEEDBACK:
            return { ...state, loading: true };
        case REQUEST_FEEDBACK_SUCCESS:
            return { ...state, loading: false };
        case REQUEST_FEEDBACK_FAILURE:
            return { ...state, loading: false };

        case FETCH_RESUME_SCORE:
            return { ...state };
        case FETCH_RESUME_SCORE_SUCCESS:
            return {
                ...state,
                resumeScore: action.payload,
            };
        case FETCH_RESUME_SCORE_FAILURE:
            return { ...state };

        case GET_CANDIDATE_DETAILS_USING_RESUME:
            return { ...state, loading: false };
        case GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS:
            return { ...state, loading: false, candidateDetails: action.payload };
        case GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE:
            return { ...state, loading: false };
        case CREATE_JD:
            return { ...state, jDLoading: true };
        case CREATE_JD_SUCCESS:
            return {
                ...state,
                jDLoading: false,
                jDData: action.payload,
            };
        case CREATE_JD_FAILURE:
            return { ...state, jDLoading: false };
        case GET_PANELIST_CALENDAR_REQUEST:
            return { ...state, loading: true };
        case GET_PANELIST_CALENDAR_REQUEST_SUCCESS:
            return { ...state, loading: false };
        case GET_PANELIST_CALENDAR_REQUEST_FAILURE:
            return { ...state, loading: false };
        case GET_CANDIDATE_CALENDAR_REQUEST:
            return { ...state, loading: true };
        case GET_CANDIDATE_CALENDAR_REQUEST_SUCCESS:
            return { ...state, loading: false };
        case GET_CANDIDATE_CALENDAR_REQUEST_FAILURE:
            return { ...state, loading: false };

        case POST_INTERVIEW:
            return { ...state, loading: true };
        case POST_INTERVIEW_SUCCESS:
            return { ...state, loading: false };
        case POST_INTERVIEW_FAILURE:
            return { ...state, loading: false, authErrorMessage: action.payload };

        case POST_BULK_INTERVIEW:
            return { ...state, bulkUploadLoading: true };
        case POST_BULK_INTERVIEW_SUCCESS:
            return {
                ...state,
                bulkUploadLoading: false,
                bulkUploadData: action.payload,
            };
        case POST_BULK_INTERVIEW_FAILURE:
            return {
                ...state,
                bulkUploadLoading: false,
                authErrorMessageV2: action.payload,
            };

        case GET_INTERVIEW:
            return { ...state, loading: true };

        case GET_INTERVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                timeStamp: state.timeStamp > action.payload.timeStamp ? state.timeStamp : action.payload.timeStamp,
                interviewList: state.timeStamp > action.payload.timeStamp ? state.interviewList : action.payload,
            };
        case GET_INTERVIEW_FAILURE:
            return { ...state, loading: false };

        case GET_INTERVIEW_STAT:
            return { ...state, loading: false };
        case GET_INTERVIEW_STAT_SUCCESS:
            return {
                ...state,
                loading: false,
                timeStamp: state.timeStamp > action.payload.timeStamp ? state.timeStamp : action.payload.timeStamp,
                interviewStat: state.timeStamp > action.payload.timeStamp ? state.interviewStat : action.payload,
            };
        case GET_INTERVIEW_STAT_FAILURE:
            return { ...state, loading: false };

        case GET_POSITION_STAT:
            return { ...state, loading: true };
        case GET_POSITION_STAT_SUCCESS:
            return { ...state, loading: false, positionStat: action.payload };
        case GET_POSITION_STAT_FAILURE:
            return { ...state, loading: false };

        case GET_CANDIDATE_STAT:
            return { ...state, loading: true };
        case GET_CANDIDATE_STAT_SUCCESS:
            return { ...state, loading: false, candidateStat: action.payload };
        case GET_CANDIDATE_STAT_FAILURE:
            return { ...state, loading: false };

        case GET_POSITION_STAT_FOR_BAR_GRAPH:
            return { ...state, positionStatForBarGraphLoading: true };
        case GET_POSITION_STAT_FOR_BAR_GRAPH_SUCCESS:
            return {
                ...state,
                positionStatForBarGraphLoading: false,
                positionStatForBarGraph: action.payload,
            };
        case GET_POSITION_STAT_FOR_BAR_GRAPH_FAILURE:
            return { ...state, positionStatForBarGraphLoading: false };

        case UPDATE_INTERVIEW_STATUS:
            return { ...state, loading: true };
        case UPDATE_INTERVIEW_STATUS_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_INTERVIEW_STATUS_FAILURE:
            return { ...state, loading: false };

        case GET_FEEDBACK:
            return { ...state, loading: true };
        case GET_FEEDBACK_SUCCESS:
            return { ...state, loading: false, feedbackData: action.payload };
        case GET_FEEDBACK_FAILURE:
            return { ...state, loading: false };

        case CREATE_CALENDAR_INVITATION:
            return { ...state, loading: false };
        case CREATE_CALENDAR_INVITATION_SUCCESS:
            return { ...state, loading: false };
        case CREATE_CALENDAR_INVITATION_FAILURE:
            return { ...state, loading: false };
        case CONTINUE_CALENDAR_INVITATION:
            return { ...state, rescheduleErrorLoading: true };
        case CONTINUE_CALENDAR_INVITATION_SUCCESS:
            return { ...state, rescheduleErrorLoading: false };
        case CONTINUE_CALENDAR_INVITATION_FAILURE:
            return { ...state, rescheduleErrorLoading: false };
        case GET_FEEDBACK_REPORT:
            return { ...state, loading: true };
        case GET_FEEDBACK_REPORT_SUCCESS:
            return { ...state, loading: false, feedbackReportData: action.payload };
        case GET_FEEDBACK_REPORT_FAILURE:
            return { ...state, loading: false };

        case GET_INTERVIEW_BY_ID:
            return { ...state, loading: true };
        case GET_INTERVIEW_BY_ID_SUCCESS:
            return { ...state, loading: false, myInterview: action.payload };
        case GET_INDUSTRY_TYPE_FAILURE:
            return { ...state, loading: false };
        case GET_INTERVIEW_BY_ID_FOR_POSITION:
            return { ...state, loading: true };
        case GET_INTERVIEW_BY_ID_FOR_POSITION_SUCCESS:
            return { ...state, loading: false, myInterviewPosition: action.payload };
        case GET_INTERVIEW_BY_ID_FOR_POSITION_FAILURE:
            return { ...state, loading: false };

        case CHECK_CANDIDATE_EXISTS:
            return { ...state, loading: true };
        case CHECK_CANDIDATE_EXISTS_SUCCESS:
            return { ...state, loading: false, checkCandidateExists: action.payload };
        case CHECK_CANDIDATE_EXISTS_FAILURE:
            return { ...state, loading: false };
        case ADD_CUSTOM_FILE:
            return { ...state, loading: true };
        case ADD_CUSTOM_FILE_SUCCESS:
            return { ...state, loading: false, customFileDetails: action.payload };
        case ADD_CUSTOM_FILE_FAILURE:
            return { ...state, loading: false };
        case RESEND_EMAIL:
            return { ...state, loading: true };
        case RESEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case RESEND_EMAIL_FAILURE:
            return { ...state, loading: false };
        case GET_INTERVIEW_INCBOT:
            return { ...state, loading: true };
        case GET_INTERVIEW_INCBOT_SUCCESS:
            return { ...state, incbotInterviewInfo: action?.payload, loading: false };
        case GET_INTERVIEW_INCBOT_FAILURE:
            return { ...state, loading: false };
        case GET_INCVID_PLATFORM_PERMISSION:
            return { ...state, loading: true };
        case GET_INCVID_PLATFORM_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                incVidPermission: action?.payload?.status,
            };
        case GET_INCVID_PLATFORM_PERMISSION_FAILURE:
            return { ...state, loading: false };
        case GET_INTERVIEW_DATA:
            return { ...state, loading: true };
        case GET_INTERVIEW_DATA_SUCCESS:
            return { ...state, loading: false };
        case GET_INTERVIEW_DATA_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
