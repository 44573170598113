import {
    ADD_OR_EDIT_SUB_USER,
    ADD_OR_EDIT_SUB_USER_SUCCESS,
    ADD_OR_EDIT_SUB_USER_FAILURE,
    GET_SUB_USER,
    GET_SUB_USER_SUCCESS,
    GET_SUB_USER_FAILURE,
    DELETE_SUB_USER,
    DELETE_SUB_USER_SUCCESS,
    DELETE_SUB_USER_FAILURE,
    ACTIVE_INACTIVE_SUBUSER,
    ACTIVE_INACTIVE_SUBUSER_SUCCESS,
    ACTIVE_INACTIVE_SUBUSER_FAILURE,
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
    GET_ROLE_BY_ID,
    GET_ROLE_BY_ID_SUCCESS,
    GET_ROLE_BY_ID_FAILURE,
    ADD_OR_EDIT_ROLE,
    ADD_OR_EDIT_ROLE_SUCCESS,
    ADD_OR_EDIT_ROLE_FAILURE,
    DELETE_ROLES,
    DELETE_ROLES_SUCCESS,
    DELETE_ROLES_FAILURE,
    ACTIVE_INACTIVE_ROLE,
    ACTIVE_INACTIVE_ROLE_SUCCESS,
    ACTIVE_INACTIVE_ROLE_FAILURE,
    GET_PERMISSION,
    GET_PERMISSION_SUCCESS,
    GET_PERMISSION_FAILURE,
    GET_USER_PERMISSION,
    GET_USER_PERMISSION_SUCCESS,
    GET_USER_PERMISSION_FAILURE,
    GET_ALL_ROLES,
    GET_ALL_ROLES_SUCCESS,
    GET_ALL_ROLES_FAILURE,
    GET_DROPDOWN_SUBUSER,
    GET_DROPDOWN_SUBUSER_SUCCESS,
    GET_DROPDOWN_SUBUSER_FAILURE,
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE,
    GET_RESOURCES,
    GET_RESOURCES_SUCCESS,
    GET_RESOURCES_FAILURE,
    SAVE_EMPLOYER_ROLE,
    SAVE_EMPLOYER_ROLE_SUCCESS,
    SAVE_EMPLOYER_ROLE_FAILURE,
    GET_EMPLOYER_ROLE,
    GET_EMPLOYER_ROLE_SUCCESS,
    GET_EMPLOYER_ROLE_FAILURE,
    SUB_EMPLOYER_PASSWORD_RESET,
    SUB_EMPLOYER_PASSWORD_RESET_SUCCESS,
    SUB_EMPLOYER_PASSWORD_RESET_FAILURE,
} from './types';

export const addOrEditSubUser = (payload) => ({
    type: ADD_OR_EDIT_SUB_USER,
    payload,
});
export const addOrEditSubUserSuccess = () => ({
    type: ADD_OR_EDIT_SUB_USER_SUCCESS,
});
export const addOrEditSubUserFailure = () => ({
    type: ADD_OR_EDIT_SUB_USER_FAILURE,
});

export const getSubUser = (payload) => ({
    type: GET_SUB_USER,
    payload,
});
export const getSubUserSuccess = (payload) => ({
    type: GET_SUB_USER_SUCCESS,
    payload,
});
export const getSubUserFailure = () => ({
    type: GET_SUB_USER_FAILURE,
});

export const deleteSubUser = (payload) => ({
    type: DELETE_SUB_USER,
    payload,
});
export const deleteSubUserSuccess = () => ({
    type: DELETE_SUB_USER_SUCCESS,
});
export const deleteSubUserFailure = () => ({
    type: DELETE_SUB_USER_FAILURE,
});

export const activeInactiveSubUser = (payload) => ({
    type: ACTIVE_INACTIVE_SUBUSER,
    payload,
});
export const activeInactiveSubUserSuccess = () => ({
    type: ACTIVE_INACTIVE_SUBUSER_SUCCESS,
});
export const activeInactiveSubUserFailure = () => ({
    type: ACTIVE_INACTIVE_SUBUSER_FAILURE,
});

export const getRoles = (action) => ({
    type: GET_ROLES,
    payload: action,
});
export const getRolesSuccess = (payload) => ({
    type: GET_ROLES_SUCCESS,
    payload,
});
export const getRolesFailure = () => ({
    type: GET_ROLES_FAILURE,
});

export const getAllRoles = (action) => ({
    type: GET_ALL_ROLES,
    payload: action,
});
export const getAllRolesSuccess = (payload) => ({
    type: GET_ALL_ROLES_SUCCESS,
    payload,
});
export const getAllRolesFailure = () => ({
    type: GET_ALL_ROLES_FAILURE,
});

export const getRoleById = () => ({
    type: GET_ROLE_BY_ID,
});
export const getRoleByIdSuccess = (payload) => ({
    type: GET_ROLE_BY_ID_SUCCESS,
    payload,
});
export const getRoleByIdFailure = () => ({
    type: GET_ROLE_BY_ID_FAILURE,
});

export const addOrEditRole = (payload) => ({
    type: ADD_OR_EDIT_ROLE,
    payload,
});
export const addOrEditRoleSuccess = () => ({
    type: ADD_OR_EDIT_ROLE_SUCCESS,
});
export const addOrEditRoleFailure = () => ({
    type: ADD_OR_EDIT_ROLE_FAILURE,
});

export const deleteRole = (payload) => ({
    type: DELETE_ROLES,
    payload,
});
export const deleteRoleSuccess = () => ({
    type: DELETE_ROLES_SUCCESS,
});
export const deleteRoleFailure = () => ({
    type: DELETE_ROLES_FAILURE,
});

export const activeInactiveRole = (payload) => ({
    type: ACTIVE_INACTIVE_ROLE,
    payload,
});
export const activeInactiveRoleSuccess = () => ({
    type: ACTIVE_INACTIVE_ROLE_SUCCESS,
});
export const activeInactiveRoleFailure = () => ({
    type: ACTIVE_INACTIVE_ROLE_FAILURE,
});

export const getPermission = () => ({
    type: GET_PERMISSION,
});
export const getPermissionSuccess = (payload) => ({
    type: GET_PERMISSION_SUCCESS,
    payload,
});
export const getPermissionFailure = () => ({
    type: GET_PERMISSION_FAILURE,
});

export const getUserPermission = () => ({
    type: GET_USER_PERMISSION,
});
export const getUserPermissionSuccess = (payload) => ({
    type: GET_USER_PERMISSION_SUCCESS,
    payload,
});
export const getUserPermissionFailure = () => ({
    type: GET_USER_PERMISSION_FAILURE,
});

export const getDropdownSubUser = (payload) => ({
    type: GET_DROPDOWN_SUBUSER,
    payload,
});
export const getDropdownSubUserSuccess = (payload) => ({
    type: GET_DROPDOWN_SUBUSER_SUCCESS,
    payload,
});
export const getDropdownSubUserFailure = () => ({
    type: GET_DROPDOWN_SUBUSER_FAILURE,
});

export const getAllUsers = () => ({
    type: GET_ALL_USERS,
});
export const getAllUsersSuccess = (payload) => ({
    type: GET_ALL_USERS_SUCCESS,
    payload,
});
export const getAllUsersFailure = () => ({
    type: GET_ALL_USERS_FAILURE,
});

export const getResources = (payload) => ({
    type: GET_RESOURCES,
    payload,
});
export const getResourcesSuccess = (payload) => ({
    type: GET_RESOURCES_SUCCESS,
    payload,
});
export const getResourcesFailure = () => ({
    type: GET_RESOURCES_FAILURE,
});

export const saveEmployerRole = (payload) => ({
    type: SAVE_EMPLOYER_ROLE,
    payload,
});
export const saveEmployerRoleSuccess = (payload) => ({
    type: SAVE_EMPLOYER_ROLE_SUCCESS,
    payload,
});
export const saveEmployerRoleFailure = () => ({
    type: SAVE_EMPLOYER_ROLE_FAILURE,
});

export const getEmployerRole = (payload) => ({
    type: GET_EMPLOYER_ROLE,
    payload,
});
export const getEmployerRoleSuccess = (payload) => ({
    type: GET_EMPLOYER_ROLE_SUCCESS,
    payload,
});
export const getEmployerRoleFailure = () => ({
    type: GET_EMPLOYER_ROLE_FAILURE,
});

export const subEmployerPasswordReset = (payload) => ({
    type: SUB_EMPLOYER_PASSWORD_RESET,
    payload,
});
export const subEmployerPasswordResetSuccess = () => ({
    type: SUB_EMPLOYER_PASSWORD_RESET_SUCCESS,
});
export const subEmployerPasswordResetFailure = () => ({
    type: SUB_EMPLOYER_PASSWORD_RESET_FAILURE,
});
