import {
    GET_APPLICANT,
    GET_APPLICANT_SUCCESS,
    GET_APPLICANT_FAILURE,
    DELETE_APPLICANT,
    DELETE_APPLICANT_SUCCESS,
    DELETE_APPLICANT_FAILURE,
    UPDATE_APPLICANT,
    UPDATE_APPLICANT_SUCCESS,
    UPDATE_APPLICANT_FAILURE,
    APPLICANT_SENT_INVITE,
    APPLICANT_SENT_INVITE_SUCCESS,
    APPLICANT_SENT_INVITE_FAILURE,
} from './types';
export const getApplicant = (payload) => ({
    type: GET_APPLICANT,
    payload,
});
export const getApplicantSuccess = (payload) => ({
    type: GET_APPLICANT_SUCCESS,
    payload,
});
export const getApplicantFailure = () => ({
    type: GET_APPLICANT_FAILURE,
});

export const deleteApplicant = (payload) => ({
    type: DELETE_APPLICANT,
    payload,
});
export const deleteApplicantSuccess = (payload) => ({
    type: DELETE_APPLICANT_SUCCESS,
    payload,
});
export const deleteApplicantFailure = () => ({
    type: DELETE_APPLICANT_FAILURE,
});

export const updateApplicant = (payload) => ({
    type: UPDATE_APPLICANT,
    payload,
});
export const updateApplicantSuccess = (payload) => ({
    type: UPDATE_APPLICANT_SUCCESS,
    payload,
});
export const updateApplicantFailure = () => ({
    type: UPDATE_APPLICANT_FAILURE,
});

export const sentInvite = (payload) => ({
    type: APPLICANT_SENT_INVITE,
    payload,
});
export const applicantSentInviteSuccess = (payload) => ({
    type: APPLICANT_SENT_INVITE_SUCCESS,
    payload,
});
export const applicantSentInviteFailure = () => ({
    type: APPLICANT_SENT_INVITE_FAILURE,
});
