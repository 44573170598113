import {
    CREATE_OR_UPDATE_CUSTOM_FIELD,
    CREATE_OR_UPDATE_CUSTOM_FIELD_FAILURE,
    CREATE_OR_UPDATE_CUSTOM_FIELD_SUCCESS,
    DELETE_CUSTOM_FIELD,
    DELETE_CUSTOM_FIELD_FAILURE,
    DELETE_CUSTOM_FIELD_SUCCESS,
    GET_ALL_CUSTOM_FIELDS,
    GET_ALL_CUSTOM_FIELDS_FAILURE,
    GET_ALL_CUSTOM_FIELDS_SUCCESS,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    customFields: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_CUSTOM_FIELDS:
            return { ...state, loading: true };
        case GET_ALL_CUSTOM_FIELDS_SUCCESS:
            return { ...state, loading: false, customFields: action.payload };
        case GET_ALL_CUSTOM_FIELDS_FAILURE:
            return { ...state, loading: false };

        case DELETE_CUSTOM_FIELD:
            return { ...state, loading: true };
        case DELETE_CUSTOM_FIELD_SUCCESS:
            return { ...state, loading: false };
        case DELETE_CUSTOM_FIELD_FAILURE:
            return { ...state, loading: false };

        case CREATE_OR_UPDATE_CUSTOM_FIELD:
            return { ...state, loading: true };
        case CREATE_OR_UPDATE_CUSTOM_FIELD_SUCCESS:
            return { ...state, loading: false };
        case CREATE_OR_UPDATE_CUSTOM_FIELD_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
