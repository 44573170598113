import React, { memo } from 'react';

const CheckBox = memo(({ name, className, id, label, onChange, checked }) => {
    return (
        <>
            <input
                type="checkbox"
                className={`${className}`}
                name={name}
                checked={checked}
                onChange={onChange}
                id={id}
            />
            <label className="mx-2" htmlFor={id}>
                {label}
            </label>
        </>
    );
});

export default CheckBox;
