import {
    SUPPORT_REQUEST,
    SUPPORT_REQUEST_SUCCESS,
    SUPPORT_REQUEST_FAILURE,
    CALL_BACK_REQUEST,
    CALL_BACK_REQUEST_SUCCESS,
    CALL_BACK_REQUEST_FAILURE,
    GET_SUPPORT_TICKET,
    GET_SUPPORT_TICKET_SUCCESS,
    GET_SUPPORT_TICKET_FAILURE,
    CLOSE_SUPPORT_TICKET,
    CLOSE_SUPPORT_TICKET_SUCCESS,
    CLOSE_SUPPORT_TICKET_FAILURE,
} from './types';

export const supportRequest = (payload) => ({
    type: SUPPORT_REQUEST,
    payload,
});
export const supportRequestSuccess = () => ({
    type: SUPPORT_REQUEST_SUCCESS,
});
export const supportRequestFailure = () => ({
    type: SUPPORT_REQUEST_FAILURE,
});

export const callBackRequest = (payload) => ({
    type: CALL_BACK_REQUEST,
    payload,
});
export const callBackRequestSuccess = () => ({
    type: CALL_BACK_REQUEST_SUCCESS,
});
export const callBackRequestFailure = () => ({
    type: CALL_BACK_REQUEST_FAILURE,
});

export const getSupportTicket = (payload) => ({
    type: GET_SUPPORT_TICKET,
    payload,
});
export const getSupportTicketSuccess = (payload) => ({
    type: GET_SUPPORT_TICKET_SUCCESS,
    payload,
});
export const getSupportTicketFailure = () => ({
    type: GET_SUPPORT_TICKET_FAILURE,
});

export const closeSupportTicket = (payload) => ({
    type: CLOSE_SUPPORT_TICKET,
    payload,
});
export const closeSupportTicketSuccess = () => ({
    type: CLOSE_SUPPORT_TICKET_SUCCESS,
});
export const closeSupportTicketFailure = () => ({
    type: CLOSE_SUPPORT_TICKET_FAILURE,
});
