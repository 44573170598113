import {
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAILURE,
    GET_PLANS_AND_PRICING,
    GET_PLANS_AND_PRICING_SUCCESS,
    GET_PLANS_AND_PRICING_FAILURE,
    UPDATE_PAYMENT_INFORMATION,
    UPDATE_PAYMENT_INFORMATION_SUCCESS,
    UPDATE_PAYMENT_INFORMATION_FAILURE,
    GET_ORDER_LIST,
    GET_ORDER_LIST_SUCCESS,
    GET_ORDER_LIST_FAILURE,
    GET_BILLING_INFORMATION,
    GET_BILLING_INFORMATION_SUCCESS,
    GET_BILLING_INFORMATION_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    productData: null,
    productData2: null,
    plansAndPricingData: null,
    orderList: null,
    billingInformation: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCT_LIST:
            return { ...state };
        case GET_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                productData: action.payload,
            };
        case GET_PRODUCT_LIST_FAILURE:
            return { ...state };

        case GET_PLANS_AND_PRICING:
            return { ...state, loading: true };
        case GET_PLANS_AND_PRICING_SUCCESS:
            return {
                ...state,
                loading: false,
                plansAndPricingData: action.payload,
            };
        case GET_PLANS_AND_PRICING_FAILURE:
            return { ...state, loading: false };
        case UPDATE_PAYMENT_INFORMATION:
            return { ...state, loading: true };
        case UPDATE_PAYMENT_INFORMATION_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_PAYMENT_INFORMATION_FAILURE:
            return { ...state, loading: false };
        case GET_ORDER_LIST:
            return { ...state, loading: true };

        case GET_ORDER_LIST_SUCCESS:
            return { ...state, loading: false, orderList: action.payload };
        case GET_ORDER_LIST_FAILURE:
            return { ...state, loading: false };

        case GET_BILLING_INFORMATION:
            return { ...state, loading: true };
        case GET_BILLING_INFORMATION_SUCCESS:
            return { ...state, loading: false, billingInformation: action.payload };
        case GET_BILLING_INFORMATION_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
