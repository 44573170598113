import React, { memo, useRef, useLayoutEffect, useState, useMemo } from 'react';
import { Button } from '../CommonComponent';
import tick from '../../assets/images/green-tick.svg';
import copy from '../../assets/images/copy-btn.svg';
import { Tooltip } from 'react-tooltip';
import { generateUrl } from '../../utils/helper';
const ProductCard = memo(({ productDetail, onClick, popup, setPopup, sendContactEnquiry, userData }) => {
    const [height, setHeight] = useState({});
    const [maxHeight, setMaxHeight] = useState(0);
    const productCardRef = useRef();
    const getLink = (name) => {
        if (name === 'IncExit') return 'exit-interview-services';
        else if (name === 'IncServe') return 'interview-as-a-service';
        else if (name === 'IncBot') return 'ai-interview';
        else if (name === 'IncFeed') return 'interview-scheduling-software';
        else if (name === 'IncVid') return 'video-interview-portal';
        else return 'crowdstaffing';
    };
    useLayoutEffect(() => {
        if (maxHeight < productCardRef.current.offsetHeight) {
            setHeight({
                height: productCardRef.current.offsetHeight + 'px',
            });
            setMaxHeight(productCardRef.current.offsetHeight);
        }
    }, [maxHeight]);
    return (
        <div className="lp-main-card" ref={productCardRef} style={height}>
            {/* <span className={`${productDetail?.status} f-14 f-med d-center`}>
          {productDetail?.status !== "Unsubscribed"
            ? productDetail?.status
            : ""}
        </span> */}
            <a href={`${generateUrl('website')}/${getLink(productDetail?.name)}`} target="_blank">
                <img
                    className="lp-copy"
                    src={copy}
                    data-tooltip-id="open-link"
                    data-tooltip-content="Detailed Information"
                    data-tooltip-place="bottom"
                />
                <Tooltip id="open-link" />
            </a>
            <div className="lp-card input-shadow" style={height}>
                <div className="lp-card-title mb-2">
                    <img src={productDetail?.icon} alt="lp-card-img" className="lp-card-img img-fluid" />
                    <div className="lp-card-name f-bol">
                        {productDetail?.name}
                        <br />
                        {productDetail?.label !== 'undefined' && productDetail?.label}
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between f-ph f-sem-bol mb-1">
                    {productDetail?.productHighlights &&
                        productDetail?.productHighlights.length > 0 &&
                        productDetail?.productHighlights.map((itm, index) =>
                            index < 6 ? (
                                <div className="lpc-detail" key={itm?._id}>
                                    <img src={tick} alt="tick-mark" className="lpc-green-tick img-fluid" />
                                    <div className="lpc-points" title={itm?.name}>
                                        {itm?.name}
                                    </div>
                                </div>
                            ) : null
                        )}
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                        <div className="f-8 f-sem-bol theme-grey">Pricing Starts from</div>
                        <div className="lpc-price f-bol">
                            &#36; {productDetail?.price}
                            <span className="f-10 f-reg">/mo</span>
                        </div>
                    </div>
                    <div className="lpc-price-btn d-flex justify-content-between align-items-end f-ph">
                        {productDetail?.isContactUsEnabled && (
                            <Button
                                text={'Contact Us'}
                                className="f-sem-bol lpc-card-btn lpc-btn"
                                onClick={() => {
                                    sendContactEnquiry({
                                        name: userData?.name,
                                        email: userData?.email,
                                        phone: userData?.phone,
                                        subject: 'Product Enquiry',
                                        message: `Requesting a callback to know about the product ${productDetail?.name}`,
                                    });
                                    setPopup(!popup);
                                }}
                            />
                        )}
                        {productDetail?.planCount > 0 && (
                            <Button
                                text={'View Plans'}
                                className="f-sem-bol rounded-pill lpc-btn lpc-blue"
                                onClick={onClick}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ProductCard;
