import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast, localStorageJsonParseDecrypt } from '../../utils/helper';

import { SUPPORT_REQUEST, CALL_BACK_REQUEST, GET_SUPPORT_TICKET, CLOSE_SUPPORT_TICKET } from '../action/types';
import {
    supportRequestSuccess,
    supportRequestFailure,
    callBackRequestSuccess,
    callBackRequestFailure,
    getSupportTicketSuccess,
    getSupportTicketFailure,
    closeSupportTicketSuccess,
    closeSupportTicketFailure,
} from '../action';
import USERS from '../../utils/api/users';

function* supportRequest(action) {
    const formData = new FormData();
    action.payload.data.desc && formData.append('message', action.payload.data.desc);
    action.payload.data.file && formData.append('file', action.payload.data.file);
    action.payload.data.type && formData.append('type', action.payload.data.type);
    try {
        const res = yield USERS.post('users/support', formData);
        if (res.status === 201) {
            yield put(supportRequestSuccess());
            yield toast.success(<Toast msg="The request was successfully sent. We will contact you soon." />);
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(supportRequestFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* callBackRequest(action) {
    try {
        const res = yield USERS.post('users/contact-inquiry', {
            name: localStorageJsonParseDecrypt('userData').name,
            email: localStorageJsonParseDecrypt('userData').email,
            phone: localStorageJsonParseDecrypt('userData').phone,
            subject: 'Contact Sales Team',
            message: action.payload.message,
        });
        if (res.status === 201) {
            yield put(callBackRequestSuccess());
            yield toast.success(<Toast msg="The request was successfully sent. We will contact you soon." />);
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(callBackRequestFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getSupportTicketsRequest(action) {
    const isSearch = action.payload.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';
    const isPageAndPageSize =
        action.payload.page && action.payload.pageSize
            ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
            : '';
    const isSort =
        action.payload.sort && action.payload.order ? `&sort=${action.payload.sort}&order=${action.payload.order}` : '';
    try {
        const res = yield USERS.get(`users/support${isPageAndPageSize}${isSort}${isSearch}`);
        if (res.status === 200) {
            yield put(getSupportTicketSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getSupportTicketFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* closeSupportTicketRequest(action) {
    try {
        const res = yield USERS.put(`users/support/status/${action.payload.id}`);
        if (res.status === 200) {
            yield call(action.payload.callback);
            yield put(closeSupportTicketSuccess(res.data.result));
            yield toast.success(<Toast msg={'Your ticket has been closed successfully.'} />);
        } else {
            yield put(closeSupportTicketFailure());
            yield toast.error(<Toast msg={'Unable to close ticket. Please contact to admin.'} />, {
                toastId: 'Unable to close ticket. Please contact to admin.',
            });
        }
    } catch (e) {
        yield put(closeSupportTicketFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}
export function* watchSupportRequestAPI() {
    yield takeEvery(SUPPORT_REQUEST, supportRequest);
}
export function* watchCallBackRequestAPI() {
    yield takeEvery(CALL_BACK_REQUEST, callBackRequest);
}
export function* watchGetSupportTicketsAPI() {
    yield takeEvery(GET_SUPPORT_TICKET, getSupportTicketsRequest);
}
export function* watchCloseSupportTicketsAPI() {
    yield takeEvery(CLOSE_SUPPORT_TICKET, closeSupportTicketRequest);
}

export default function* rootSaga() {
    yield all([
        watchSupportRequestAPI(),
        watchCallBackRequestAPI(),
        watchGetSupportTicketsAPI(),
        watchCloseSupportTicketsAPI(),
    ]);
}
