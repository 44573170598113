import React, { memo } from 'react';
import excel from '../../assets/images/excel-btn.svg';
import { accessRoutes } from '../../utils/helper';

const ExcelBtn = memo(({ onClick, Disabled }) => {
    return (
        <>
            <button
                className={`d-center rounded ${Disabled ? 'cursor-na excel-btn-disabled' : 'excel-btn'}`}
                onClick={onClick}
                disabled={Disabled}
                title={Disabled ? 'Permission not enabled' : 'Excel Download'}
            >
                <span className="f-l f-med">Export To Excel</span>
                <img src={excel} alt="excel" className="excel-img img-fluid" />
            </button>
        </>
    );
});

export default ExcelBtn;
