import React, { useEffect, useState } from 'react';
import BulkUploadDetails from './BulkUploadDetailsCard';
import CloseButton from './CloseButton';
import arrow from '../../assets/images/arrow-down.svg';

const BulkUploadPopup = ({
    setBulkUploadPopup,
    bulkUploadData,
    setConfirmBulkUpload,
    type,
    handleClearFileUpload,
    setPanelist,
}) => {
    const [successDrop, setSuccessDrop] = useState(false);
    const [errorDrop, setErrorDrop] = useState(false);
    const [duplicateDrop, setDuplicateDrop] = useState(false);
    return (
        <div className="bu-popup">
            <div className="bu-popup-box">
                <div className="d-flex justify-content-between w-100">
                    <span className="f-20 f-bol">
                        Total Records :{bulkUploadData?.data?.length > 0 ? bulkUploadData?.data?.length : '0'}
                    </span>
                    <CloseButton
                        onClick={() => {
                            if (setPanelist) setPanelist(null);
                            setBulkUploadPopup(false);
                        }}
                    />
                </div>
                {/* success record container */}
                <div className="accordion-container">
                    <div
                        className={`accordion ${bulkUploadData?.data?.success?.length > 0 ? 'pointer' : ''} accordion-success`}
                    >
                        <div
                            className="main-info-box"
                            onClick={() => {
                                if (bulkUploadData?.data?.success?.length > 0) {
                                    setSuccessDrop(!successDrop);
                                    setErrorDrop(false);
                                    setDuplicateDrop(false);
                                }
                            }}
                        >
                            <div className={`title f-16 f-bol`}>
                                {bulkUploadData?.data?.success?.length > 0
                                    ? `Success Records : ${bulkUploadData?.data?.success?.length} Records Found`
                                    : 'Success Records : 0 Records Found'}
                            </div>
                            <div className="btn-box">
                                {bulkUploadData?.data?.success?.length > 0 ? (
                                    <button
                                        className="dropdown f-12"
                                        onClick={() => {
                                            setSuccessDrop(!successDrop);
                                            setErrorDrop(false);
                                            setDuplicateDrop(false);
                                        }}
                                    >
                                        <img
                                            priority={true}
                                            src={arrow}
                                            alt="arrow"
                                            className={`arrow ${successDrop ? 'down' : ''}`}
                                        />
                                    </button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className={`detail-box f-14 ${successDrop ? 'full-height' : ''}`} id="detail">
                            <div className="details">
                                <div className="header d-flex f-12 f-sem-bol">
                                    <div className="header-content w-20">
                                        <span>Name</span>
                                    </div>
                                    <div className="header-content w-30">
                                        <span>Email</span>
                                    </div>
                                    <div className="header-content w-20">
                                        <span>Experience</span>
                                    </div>
                                    {type !== 'panelist' && (
                                        <div className="header-content w-30">
                                            <span>Resume</span>
                                        </div>
                                    )}
                                    <div className="header-content w-30">
                                        <span>Phone</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sp-list1 bulk-upload-row-wrapper">
                                {bulkUploadData && bulkUploadData?.data && bulkUploadData?.data?.success?.length > 0 ? (
                                    bulkUploadData?.data?.success?.map((data, i) => (
                                        <BulkUploadDetails type={type} data={data} key={i} status="success" />
                                    ))
                                ) : (
                                    <div className="d-flex justify-content-center text-center text-dark">
                                        No Records found
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* duplicate record container */}
                <div className="accordion-container">
                    <div
                        className={`accordion ${bulkUploadData?.data?.warnings?.length > 0 ? 'pointer' : ''} accordion-duplicate`}
                    >
                        <div
                            className="main-info-box"
                            onClick={() => {
                                if (bulkUploadData?.data?.warnings?.length > 0) {
                                    setDuplicateDrop(!duplicateDrop);
                                    setErrorDrop(false);
                                    setSuccessDrop(false);
                                }
                            }}
                        >
                            <div className={`title f-16 f-bol`}>
                                {bulkUploadData?.data?.warnings?.length > 0
                                    ? `Duplicate Records: ${bulkUploadData?.data?.warnings?.length} Records Found`
                                    : 'Duplicate Records : 0 Records Found'}
                            </div>
                            <div className="btn-box">
                                {bulkUploadData?.data?.warnings?.length > 0 ? (
                                    <button
                                        className="dropdown f-12"
                                        onClick={() => {
                                            setDuplicateDrop(!duplicateDrop);
                                            setErrorDrop(false);
                                            setSuccessDrop(false);
                                        }}
                                    >
                                        <img
                                            priority={true}
                                            src={arrow}
                                            alt="arrow"
                                            className={`arrow ${duplicateDrop ? 'down' : ''}`}
                                        />
                                    </button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className={`detail-box f-14 ${duplicateDrop ? 'full-height' : ''}`} id="detail">
                            <div className="details">
                                <div className="header d-flex f-12 f-sem-bol">
                                    <div className="header-content w-20">
                                        <span>Name</span>
                                    </div>
                                    <div className="header-content w-30">
                                        <span>Email</span>
                                    </div>
                                    <div className="header-content w-20">
                                        <span>Experience</span>
                                    </div>
                                    {type !== 'panelist' && (
                                        <div className="header-content w-30">
                                            <span>Resume</span>
                                        </div>
                                    )}
                                    <div className="header-content w-30">
                                        <span>Message</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sp-list1 bulk-upload-row-wrapper">
                                {bulkUploadData &&
                                bulkUploadData?.data &&
                                bulkUploadData?.data?.warnings?.length > 0 ? (
                                    bulkUploadData?.data?.warnings?.map((data, i) => (
                                        <BulkUploadDetails type={type} data={data} key={i} />
                                    ))
                                ) : (
                                    <div className="d-flex justify-content-center text-center text-dark">
                                        No Records found
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* error record container */}
                <div className="accordion-container">
                    <div
                        className={`accordion ${bulkUploadData?.data?.errors?.length > 0 ? 'pointer' : ''} accordion-error`}
                    >
                        <div
                            className="main-info-box"
                            onClick={() => {
                                if (bulkUploadData?.data?.errors?.length > 0) {
                                    setErrorDrop(!errorDrop);
                                    setSuccessDrop(false);
                                    setDuplicateDrop(false);
                                }
                            }}
                        >
                            <div className={`title f-16 f-bol`}>
                                {bulkUploadData?.data?.errors?.length > 0
                                    ? `Error Records: ${bulkUploadData?.data?.errors?.length} Records Found`
                                    : 'Error Records: 0 Records Found'}
                            </div>
                            <div className="btn-box">
                                {bulkUploadData?.data?.errors?.length > 0 ? (
                                    <button
                                        className="dropdown f-12"
                                        onClick={() => {
                                            setErrorDrop(!errorDrop);
                                            setSuccessDrop(false);
                                            setDuplicateDrop(false);
                                        }}
                                    >
                                        <img
                                            priority={true}
                                            src={arrow}
                                            alt="arrow"
                                            className={`arrow ${errorDrop ? 'down' : ''}`}
                                        />
                                    </button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className={`detail-box f-14 ${errorDrop ? 'full-height' : ''}`} id="detail">
                            <div className="details">
                                <div className="header d-flex f-12 f-sem-bol">
                                    <div className="header-content w-20">
                                        <span>Name</span>
                                    </div>
                                    <div className="header-content w-30">
                                        <span>Email</span>
                                    </div>
                                    <div className="header-content w-20">
                                        <span>Experience</span>
                                    </div>
                                    {type !== 'panelist' && (
                                        <div className="header-content w-30">
                                            <span>Resume</span>
                                        </div>
                                    )}
                                    <div className="header-content w-30">
                                        <span>Message</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sp-list1 bulk-upload-row-wrapper">
                                {bulkUploadData && bulkUploadData?.data && bulkUploadData?.data?.errors?.length > 0 ? (
                                    bulkUploadData?.data?.errors?.map((data, i) => (
                                        <BulkUploadDetails type={type} data={data} key={i} />
                                    ))
                                ) : (
                                    <div className="d-flex justify-content-center text-center text-dark">
                                        No Records found
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-wrapper w-100 mt-3">
                    <div className="f-bol f-14 text-dark">NOTE:</div>
                    <span className="f-sem-bol f-12 d-flex text-dark">
                        {type === 'panelist' ? (
                            <>
                                <div className=" ms-1 f-bol">Success records will be uploaded.</div>
                                <div className="ms-1 f-bol">Duplicate and Error records will be rejected.</div>
                            </>
                        ) : (
                            <>
                                <div className=" ms-1 f-bol">Success records will be uploaded.</div>
                                <div className="ms-1 f-bol">Error records will be rejected.</div>
                                <div className="ms-1 f-bol">Duplicate records can be skipped or uploaded.</div>
                            </>
                        )}
                    </span>
                </div>
                <div className="d-flex justify-content-between button-wrapper d-center w-100 mt-2">
                    <div
                        className="cancel-btn buttons text-center rounded my-2 cursor-pointer py-2 px-1"
                        onClick={() => {
                            setBulkUploadPopup(false);
                            if (setPanelist) setPanelist(null);
                            if (handleClearFileUpload) {
                                handleClearFileUpload();
                            }
                        }}
                    >
                        <span className="f-sem-bol f-14 cursor-pointer">Cancel Upload</span>
                    </div>
                    {bulkUploadData &&
                    bulkUploadData?.data &&
                    bulkUploadData?.data?.warnings?.length > 0 &&
                    type !== 'panelist' ? (
                        <>
                            <div
                                className="skip-conti-btn text-center buttons rounded my-2 cursor-pointer py-2 px-1"
                                onClick={() => {
                                    setConfirmBulkUpload('1');
                                }}
                            >
                                <span className="f-sem-bol f-14 cursor-pointer">Skip Duplicates</span>
                            </div>
                            <div
                                className="conti-btn text-center buttons rounded my-2 cursor-pointer py-2 px-1"
                                onClick={() => {
                                    setConfirmBulkUpload('2');
                                }}
                            >
                                <span className="f-sem-bol f-14 cursor-pointer">Continue With Duplicates</span>
                            </div>
                        </>
                    ) : bulkUploadData && bulkUploadData?.data && bulkUploadData?.data?.success?.length > 0 ? (
                        <div
                            className="conti-btn text-center buttons rounded my-2 cursor-pointer py-2 px-1"
                            onClick={() => {
                                setConfirmBulkUpload('1');
                            }}
                        >
                            <span className="f-sem-bol f-14 cursor-pointer">Continue Upload</span>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default BulkUploadPopup;
