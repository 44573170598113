import {
    CREATE_OR_UPDATE_CUSTOM_FIELD,
    CREATE_OR_UPDATE_CUSTOM_FIELD_FAILURE,
    CREATE_OR_UPDATE_CUSTOM_FIELD_SUCCESS,
    DELETE_CUSTOM_FIELD,
    DELETE_CUSTOM_FIELD_FAILURE,
    DELETE_CUSTOM_FIELD_SUCCESS,
    GET_ALL_CUSTOM_FIELDS,
    GET_ALL_CUSTOM_FIELDS_FAILURE,
    GET_ALL_CUSTOM_FIELDS_SUCCESS,
} from './types';

export const getAllCustomFieldsRequest = (payload) => ({
    type: GET_ALL_CUSTOM_FIELDS,
    payload,
});
export const getAllCustomFieldsRequestSuccess = (payload) => ({
    type: GET_ALL_CUSTOM_FIELDS_SUCCESS,
    payload,
});
export const getAllCustomFieldsRequestFailure = () => ({
    type: GET_ALL_CUSTOM_FIELDS_FAILURE,
});

export const deleteCustomFieldRequest = (payload) => ({
    type: DELETE_CUSTOM_FIELD,
    payload,
});
export const deleteCustomFieldRequestSuccess = () => ({
    type: DELETE_CUSTOM_FIELD_SUCCESS,
});
export const deleteCustomFieldRequestFailure = () => ({
    type: DELETE_CUSTOM_FIELD_FAILURE,
});

export const createOrUpdateCustomFieldRequest = (payload) => ({
    type: CREATE_OR_UPDATE_CUSTOM_FIELD,
    payload,
});
export const createOrUpdateCustomFieldRequestSuccess = () => ({
    type: CREATE_OR_UPDATE_CUSTOM_FIELD_SUCCESS,
});
export const createOrUpdateCustomFieldRequestFailure = () => ({
    type: CREATE_OR_UPDATE_CUSTOM_FIELD_FAILURE,
});
