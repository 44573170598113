import {
    ADD_OR_EDIT_SUB_USER,
    ADD_OR_EDIT_SUB_USER_SUCCESS,
    ADD_OR_EDIT_SUB_USER_FAILURE,
    GET_SUB_USER,
    GET_SUB_USER_SUCCESS,
    GET_SUB_USER_FAILURE,
    DELETE_SUB_USER,
    DELETE_SUB_USER_SUCCESS,
    DELETE_SUB_USER_FAILURE,
    ACTIVE_INACTIVE_SUBUSER,
    ACTIVE_INACTIVE_SUBUSER_SUCCESS,
    ACTIVE_INACTIVE_SUBUSER_FAILURE,
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
    ADD_OR_EDIT_ROLE,
    ADD_OR_EDIT_ROLE_SUCCESS,
    ADD_OR_EDIT_ROLE_FAILURE,
    DELETE_ROLES,
    DELETE_ROLES_SUCCESS,
    DELETE_ROLES_FAILURE,
    ACTIVE_INACTIVE_ROLE,
    ACTIVE_INACTIVE_ROLE_SUCCESS,
    ACTIVE_INACTIVE_ROLE_FAILURE,
    GET_PERMISSION,
    GET_PERMISSION_SUCCESS,
    GET_PERMISSION_FAILURE,
    GET_USER_PERMISSION,
    GET_USER_PERMISSION_SUCCESS,
    GET_USER_PERMISSION_FAILURE,
    GET_ROLE_BY_ID,
    GET_ROLE_BY_ID_SUCCESS,
    GET_ROLE_BY_ID_FAILURE,
    GET_ALL_ROLES,
    GET_ALL_ROLES_SUCCESS,
    GET_ALL_ROLES_FAILURE,
    GET_DROPDOWN_SUBUSER,
    GET_DROPDOWN_SUBUSER_SUCCESS,
    GET_DROPDOWN_SUBUSER_FAILURE,
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE,
    GET_RESOURCES,
    GET_RESOURCES_SUCCESS,
    GET_RESOURCES_FAILURE,
    SAVE_EMPLOYER_ROLE,
    SAVE_EMPLOYER_ROLE_SUCCESS,
    SAVE_EMPLOYER_ROLE_FAILURE,
    GET_EMPLOYER_ROLE,
    GET_EMPLOYER_ROLE_SUCCESS,
    GET_EMPLOYER_ROLE_FAILURE,
    SUB_EMPLOYER_PASSWORD_RESET,
    SUB_EMPLOYER_PASSWORD_RESET_SUCCESS,
    SUB_EMPLOYER_PASSWORD_RESET_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    pLoading: false,
    subUserList: null,
    rolesList: null,
    allRolesList: null,
    userRoleList: null,
    permissionList: null,
    userPermissionList: null,
    subuserDropdown: null,
    allUsers: null,
    resources: null,
    employerRoles: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_OR_EDIT_SUB_USER:
            return { ...state, loading: true };
        case ADD_OR_EDIT_SUB_USER_SUCCESS:
            return { ...state, loading: false };
        case ADD_OR_EDIT_SUB_USER_FAILURE:
            return { ...state, loading: false };
        case GET_SUB_USER:
            return { ...state, loading: true };
        case GET_SUB_USER_SUCCESS:
            return { ...state, loading: false, subUserList: action.payload };
        case GET_SUB_USER_FAILURE:
            return { ...state, loading: false };
        case GET_DROPDOWN_SUBUSER:
            return { ...state, loading: true };
        case GET_DROPDOWN_SUBUSER_SUCCESS:
            return { ...state, loading: false, subuserDropdown: action.payload };
        case GET_DROPDOWN_SUBUSER_FAILURE:
            return { ...state, loading: false };
        case DELETE_SUB_USER:
            return { ...state, loading: true };
        case DELETE_SUB_USER_SUCCESS:
            return { ...state, loading: false };
        case DELETE_SUB_USER_FAILURE:
            return { ...state, loading: false };
        case ACTIVE_INACTIVE_SUBUSER:
            return { ...state, loading: true };
        case ACTIVE_INACTIVE_SUBUSER_SUCCESS:
            return { ...state, loading: false };
        case ACTIVE_INACTIVE_SUBUSER_FAILURE:
            return { ...state, loading: false };
        case ADD_OR_EDIT_ROLE:
            return { ...state, loading: true };
        case ADD_OR_EDIT_ROLE_SUCCESS:
            return { ...state, loading: false };
        case ADD_OR_EDIT_ROLE_FAILURE:
            return { ...state, loading: false };
        case GET_ROLES:
            return { ...state, loading: true };
        case GET_ROLES_SUCCESS:
            return { ...state, loading: false, rolesList: action.payload };
        case GET_ROLES_FAILURE:
            return { ...state, loading: false };
        case GET_ALL_ROLES:
            return { ...state, loading: true };
        case GET_ALL_ROLES_SUCCESS:
            return { ...state, loading: false, allRolesList: action.payload };
        case GET_ALL_ROLES_FAILURE:
            return { ...state, loading: false };
        case DELETE_ROLES:
            return { ...state, loading: true };
        case DELETE_ROLES_SUCCESS:
            return { ...state, loading: false };
        case GET_ROLE_BY_ID:
            return { ...state, loading: true };
        case GET_ROLE_BY_ID_SUCCESS:
            return { ...state, loading: false, selectedRole: action.payload };
        case GET_ROLE_BY_ID_FAILURE:
            return { ...state, loading: false };
        case DELETE_ROLES_FAILURE:
            return { ...state, loading: false };
        case ACTIVE_INACTIVE_ROLE:
            return { ...state, loading: true };
        case ACTIVE_INACTIVE_ROLE_SUCCESS:
            return { ...state, loading: false };
        case ACTIVE_INACTIVE_ROLE_FAILURE:
            return { ...state, loading: false };
        case GET_PERMISSION:
            return { ...state, loading: true };
        case GET_PERMISSION_SUCCESS:
            return { ...state, loading: false, permissionList: action.payload };
        case GET_PERMISSION_FAILURE:
            return { ...state, loading: false };
        case GET_USER_PERMISSION:
            return { ...state };
        case GET_USER_PERMISSION_SUCCESS:
            return { ...state, userPermissionList: action.payload };
        case GET_USER_PERMISSION_FAILURE:
            return { ...state };
        case GET_ALL_USERS:
            return { ...state, loading: true };
        case GET_ALL_USERS_SUCCESS:
            return { ...state, loading: false, allUsers: action.payload };
        case GET_ALL_USERS_FAILURE:
            return { ...state, loading: false };

        case GET_RESOURCES:
            return { ...state, loading: true };
        case GET_RESOURCES_SUCCESS:
            return { ...state, loading: false, resources: action.payload };
        case GET_RESOURCES_FAILURE:
            return { ...state, loading: false };

        case SAVE_EMPLOYER_ROLE:
            return { ...state, loading: true };
        case SAVE_EMPLOYER_ROLE_SUCCESS:
            return { ...state, loading: false };
        case SAVE_EMPLOYER_ROLE_FAILURE:
            return { ...state, loading: false };

        case GET_EMPLOYER_ROLE:
            return { ...state, loading: true };
        case GET_EMPLOYER_ROLE_SUCCESS:
            return { ...state, loading: false, employerRoles: action.payload };
        case GET_EMPLOYER_ROLE_FAILURE:
            return { ...state, loading: false };
        case SUB_EMPLOYER_PASSWORD_RESET:
            return { ...state, loading: true };
        case SUB_EMPLOYER_PASSWORD_RESET_SUCCESS:
            return { ...state, loading: false };
        case SUB_EMPLOYER_PASSWORD_RESET_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
