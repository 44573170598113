import axios from 'axios';
import { redirectPermission, generateUrl } from '../helper';

const permission = axios.create({
    baseURL: generateUrl('user_backend'),
});

permission.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

permission.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            redirectPermission();
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export default permission;
