import React, { useEffect } from 'react';
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge } from 'react-simple-captcha';
import Input from './Input';

const CaptchaComponent = ({ captchaText, handleCaptchaInputChange }) => {
    useEffect(() => {
        loadCaptchaEnginge(6, '#EAEAEA', '#000000');
    }, []);

    return (
        <div className="d-flex w-100 flex-column justify-content-center align-items-center">
            {/* <LoadCanvasTemplate /> */}
            <LoadCanvasTemplateNoReload />
            <Input
                labelClass={'f-14 f-sem-bol my-1'}
                inputClass={'f-14 f-reg w-100  bg-white input-shadow'}
                className={'common-input  border border-secondary rounded '}
                placeholder="Enter the captcha"
                value={captchaText}
                onChange={(e) => handleCaptchaInputChange(e.target.value)}
                isRequired={true}
                borderNone
            />
        </div>
    );
};

export default CaptchaComponent;
