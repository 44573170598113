import {
    GET_DOMAIN,
    GET_DOMAIN_SUCCESS,
    GET_DOMAIN_FAILURE,
    GET_MANDATORY_SKILL,
    GET_MANDATORY_SKILL_SUCCESS,
    GET_MANDATORY_SKILL_FAILURE,
    GET_OPTIONAL_SKILL,
    GET_OPTIONAL_SKILL_SUCCESS,
    GET_OPTIONAL_SKILL_FAILURE,
    GET_SCREENING_QUESTIONS,
    GET_SCREENING_QUESTIONS_SUCCESS,
    GET_SCREENING_QUESTIONS_FAILURE,
    DELETE_SCREENING_QUESTIONS,
    DELETE_SCREENING_QUESTIONS_SUCCESS,
    DELETE_SCREENING_QUESTIONS_FAILURE,
    CREATE_OR_UPDATE_POSITION,
    CREATE_OR_UPDATE_POSITION_SUCCESS,
    CREATE_OR_UPDATE_POSITION_FAILURE,
    GET_POSITION,
    GET_POSITION_SUCCESS,
    GET_POSITION_FAILURE,
    GET_POSITION_LIST,
    GET_POSITION_LIST_SUCCESS,
    GET_POSITION_LIST_FAILURE,
    DELETE_POSITION,
    DELETE_POSITION_SUCCESS,
    DELETE_POSITION_FAILURE,
    POSITIONAUTHERRORS,
    GET_SHORTLISTED_CANDIDATES,
    GET_SHORTLISTED_CANDIDATES_SUCCESS,
    GET_SHORTLISTED_CANDIDATES_FAILURE,
    GET_AI_SCREENING_QUESTIONS,
    GET_AI_SCREENING_QUESTIONS_SUCCESS,
    GET_AI_SCREENING_QUESTIONS_FAILURE,
    CREATE_JD,
    CREATE_JD_SUCCESS,
    CREATE_JD_FAILURE,
    COMPARE_JD_CV,
    COMPARE_JD_CV_SUCCESS,
    COMPARE_JD_CV_FAILURE,
    GET_DROPDOWN_POSITION,
    GET_DROPDOWN_POSITION_SUCCESS,
    GET_DROPDOWN_POSITION_FAILURE,
    CHANGE_POSITION_STATUS,
    CHANGE_POSITION_STATUS_SUCCESS,
    CHANGE_POSITION_STATUS_FAILURE,
    GET_GUEST_LIST,
    GET_GUEST_LIST_SUCCESS,
    GET_GUEST_LIST_FAILURE,
    GET_WHITELABEL,
    GET_WHITELABEL_SUCCESS,
    GET_WHITELABEL_FAILURE,
    MAKE_POSITION_FROM_INCBOT,
    MAKE_POSITION_FROM_INCBOT_SUCCESS,
    MAKE_POSITION_FROM_INCBOT_FAILURE,
    GET_LATEST_ACTIVE_POSITIONS,
    GET_LATEST_ACTIVE_POSITIONS_SUCCESS,
    GET_LATEST_ACTIVE_POSITIONS_FAILURE,
    FETCH_SKILLS,
    FETCH_SKILLS_SUCCESS,
    FETCH_SKILLS_FAILURE,
    FETCH_QUESTIONS,
    FETCH_QUESTIONS_SUCCESS,
    FETCH_QUESTIONS_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    domain: null,
    mandatorySkill: null,
    optionalSkill: null,
    screeningQuestions: null,
    positionList: null,
    authErrorMessage: null,
    shortlistedCandidates: null,
    predefinedPositionList: null,
    aiScreeningQuestions: null,
    jDLoading: false,
    compareData: null,
    compareLoading: false,
    timeStamp: null,
    dropdownPositionList: null,
    guestList: null,
    whiteLabel: null,
    newPositionDetails: null,
    latestActivePositions: null,
    fetchLoading: false,
    fetchQLoading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_QUESTIONS:
            return { ...state, fetchQLoading: true };
        case FETCH_QUESTIONS_SUCCESS:
            return {
                ...state,
                fetchQLoading: false,
            };
        case FETCH_QUESTIONS_FAILURE:
            return { ...state, fetchQLoading: false };

        case FETCH_SKILLS:
            return { ...state, fetchLoading: true };
        case FETCH_SKILLS_SUCCESS:
            return {
                ...state,
                fetchLoading: false,
            };
        case FETCH_SKILLS_FAILURE:
            return { ...state, fetchLoading: false };
        case GET_WHITELABEL:
            return { ...state, loading: true };
        case GET_WHITELABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                whiteLabel: action.payload,
            };
        case GET_WHITELABEL_FAILURE:
            return { ...state, loading: false };
        case COMPARE_JD_CV:
            return { ...state, compareLoading: true, compareData: '0' };
        case COMPARE_JD_CV_SUCCESS:
            return {
                ...state,
                compareLoading: false,
                compareData: action.payload,
            };

        case COMPARE_JD_CV_FAILURE:
            return { ...state, compareLoading: false, compareData: '0' };
        case GET_DOMAIN:
            return { ...state, loading: true };
        case GET_DOMAIN_SUCCESS:
            return {
                ...state,
                loading: false,
                domain: action.payload,
            };
        case GET_DOMAIN_FAILURE:
            return { ...state, loading: false };

        case GET_MANDATORY_SKILL:
            return { ...state, loading: true };
        case GET_MANDATORY_SKILL_SUCCESS:
            return {
                ...state,
                loading: false,
                mandatorySkill: action.payload,
            };
        case GET_MANDATORY_SKILL_FAILURE:
            return { ...state, loading: false };

        case GET_OPTIONAL_SKILL:
            return { ...state, loading: true };
        case GET_OPTIONAL_SKILL_SUCCESS:
            return {
                ...state,
                loading: false,
                optionalSkill: action.payload,
            };
        case GET_OPTIONAL_SKILL_FAILURE:
            return { ...state, loading: false };

        case GET_SCREENING_QUESTIONS:
            return { ...state, loading: true };
        case GET_SCREENING_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                screeningQuestions: action.payload,
            };
        case GET_SCREENING_QUESTIONS_FAILURE:
            return { ...state, loading: false };

        case DELETE_SCREENING_QUESTIONS:
            return { ...state, loading: true };
        case DELETE_SCREENING_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_SCREENING_QUESTIONS_FAILURE:
            return { ...state, loading: false };

        case CREATE_OR_UPDATE_POSITION:
            return { ...state, loading: true };
        case CREATE_OR_UPDATE_POSITION_SUCCESS:
            return { ...state, loading: false };
        case CREATE_OR_UPDATE_POSITION_FAILURE:
            return { ...state, loading: false, authErrorMessage: action.payload };
        case POSITIONAUTHERRORS:
            return { ...state, loading: false, authErrorMessage: null };

        case GET_POSITION:
            return { ...state, loading: true };
        case GET_POSITION_SUCCESS:
            return {
                ...state,
                loading: false,
                timeStamp: state.timeStamp > action.payload.timeStamp ? state.timeStamp : action.payload.timeStamp,
                positionList: state.timeStamp > action.payload.timeStamp ? state.positionList : action.payload,
            };
        case GET_POSITION_FAILURE:
            return { ...state, loading: false };
        case GET_DROPDOWN_POSITION:
            return { ...state, loading: true };
        case GET_DROPDOWN_POSITION_SUCCESS:
            return {
                ...state,
                loading: false,
                dropdownPositionList: action.payload,
            };
        case GET_DROPDOWN_POSITION_FAILURE:
            return { ...state, loading: false };
        case GET_POSITION_LIST:
            return { ...state, loading: true };
        case GET_POSITION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                timeStamp: state.timeStamp > action.payload.timeStamp ? state.timeStamp : action.payload.timeStamp,
                predefinedPositionList:
                    state.timeStamp > action.payload.timeStamp ? state.predefinedPositionList : action.payload,
            };
        case GET_POSITION_LIST_FAILURE:
            return { ...state, loading: false };

        case CHANGE_POSITION_STATUS:
            return { ...state, loading: true };
        case CHANGE_POSITION_STATUS_SUCCESS:
            return { ...state, loading: false };
        case CHANGE_POSITION_STATUS_FAILURE:
            return { ...state, loading: false };

        case DELETE_POSITION:
            return { ...state, loading: true };
        case DELETE_POSITION_SUCCESS:
            return { ...state, loading: false };
        case DELETE_POSITION_FAILURE:
            return { ...state, loading: false };

        case GET_SHORTLISTED_CANDIDATES:
            return { ...state, loading: true, shortlistedCandidates: null };
        case GET_SHORTLISTED_CANDIDATES_SUCCESS:
            return {
                ...state,
                loading: false,
                shortlistedCandidates: action.payload,
            };
        case GET_SHORTLISTED_CANDIDATES_FAILURE:
            return { ...state, loading: false, shortlistedCandidates: null };
        case GET_AI_SCREENING_QUESTIONS:
            return { ...state, qsLoading: true };
        case GET_AI_SCREENING_QUESTIONS_SUCCESS:
            return {
                ...state,
                qsLoading: false,
                aiScreeningQuestions: action.payload,
            };
        case GET_AI_SCREENING_QUESTIONS_FAILURE:
            return { ...state, qsLoading: false };

        case GET_GUEST_LIST:
            return { ...state, loading: true };
        case GET_GUEST_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                guestList: action.payload,
            };
        case GET_GUEST_LIST_FAILURE:
            return { ...state, loading: false };

        case MAKE_POSITION_FROM_INCBOT:
            return { ...state, loading: true };
        case MAKE_POSITION_FROM_INCBOT_SUCCESS:
            return { ...state, loading: false, newPositionDetails: action?.payload };
        case MAKE_POSITION_FROM_INCBOT_FAILURE:
            return { ...state, loading: false };

        case GET_LATEST_ACTIVE_POSITIONS:
            return { ...state, loading: true };
        case GET_LATEST_ACTIVE_POSITIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                latestActivePositions: action.payload,
            };
        case GET_LATEST_ACTIVE_POSITIONS_FAILURE:
            return { ...state, loading: false };

        default:
            return state;
    }
};
