import React, { memo, useState } from 'react';
import uploadFile from '../../assets/images/upload.svg';
import redTrash from '../../assets/images/red-trash.svg';
import { capitalizeAll } from '../../utils/helper';

const FileInputComponent = memo(({ name, placeholder, inputClass, disabled, ref }) => {
    const [empty, setEmpty] = useState(true);
    return (
        <div
            className={`position-relative bulk-file-input bg-white d-center rounded ${
                empty ? 'blue-border' : 'no-border'
            }`}
        >
            <input
                id="fileInput"
                type={'file'}
                className={`${inputClass} position-relative`}
                name={name}
                placeholder={capitalizeAll(placeholder)}
                onChange={(e) => {
                    if (e?.target?.files[0] !== 0) {
                        setEmpty(!empty);
                    } else {
                        setEmpty(empty);
                    }
                }}
                disabled={disabled}
                ref={ref}
            />
            <span className={`input-status position-absolute f-12 f-sem-bol ${empty ? 'file-empty' : 'file-present'}`}>
                {empty ? 'Upload File' : 'Uploaded'}
            </span>
            {empty ? (
                <img src={uploadFile} alt="file" className="upload-file position-absolute img-fluid" />
            ) : (
                <img
                    src={redTrash}
                    alt="close"
                    className="img-fluid red-trash position-absolute"
                    onClick={() => {
                        document.getElementById('fileInput').value = null;
                        setEmpty(!empty);
                    }}
                />
            )}
        </div>
    );
});

export default FileInputComponent;
