import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/incruiter-logo.svg';
import errorImg from '../assets/images/404.svg';
import { Button } from '../component/CommonComponent';

const NotFound = memo(() => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate('/dashboard');
    };

    return (
        <div className="error-page-wrapper w-100 h-100">
            <div className="logo-container d-flex align-items-center">
                <img src={logo} alt="logo" className="img-fluid h-75 ms-5" />
            </div>
            <div className="error-content-wrapper d-flex flex-column align-items-center">
                <div className="error-title fs-2 f-bol">OOPS!</div>
                <div className="error-title fs-2 f-bol">Error 404- Page not found</div>
                <div className="error-subtitle  -danger f-l f-bol mt-1">
                    We could not find what you were looking for
                </div>
                <img src={errorImg} alt="error" className="error-img img-fluid mt-4" />
                <Button
                    text={'Back to Home'}
                    onClick={handleGoBack}
                    className={'home-btn f-l f-sem-bol cpp-btn mt-4 common-btn'}
                />
            </div>
        </div>
    );
});

export default NotFound;
