import {
    ADD_PANELIST,
    ADD_PANELIST_SUCCESS,
    ADD_PANELIST_FAILURE,
    GET_PANELIST,
    GET_PANELIST_SUCCESS,
    GET_PANELIST_FAILURE,
    EDIT_PANELIST,
    EDIT_PANELIST_SUCCESS,
    EDIT_PANELIST_FAILURE,
    DELETE_PANELIST,
    DELETE_PANELIST_SUCCESS,
    DELETE_PANELIST_FAILURE,
    UPDATE_PANELIST_STATUS,
    UPDATE_PANELIST_STATUS_SUCCESS,
    UPDATE_PANELIST_STATUS_FAILURE,
    POST_BULK_PANELIST,
    POST_BULK_PANELIST_SUCCESS,
    POST_BULK_PANELIST_FAILURE,
    NOTIFY_PANELIST,
    NOTIFY_PANELIST_SUCCESS,
    NOTIFY_PANELIST_FAILURE,
    GET_ALL_PANELISTS,
    GET_ALL_PANELISTS_FAILURE,
    GET_ALL_PANELISTS_SUCCESS,
} from './types';

export const notifyPanelist = (payload) => ({
    type: NOTIFY_PANELIST,
    payload,
});

export const notifyPanelistSuccess = (payload) => ({
    type: NOTIFY_PANELIST_SUCCESS,
    payload,
});

export const notifyPanelistFailure = (payload) => ({
    type: NOTIFY_PANELIST_FAILURE,
    payload,
});

export const postBulkPanelist = (payload) => ({
    type: POST_BULK_PANELIST,
    payload,
});

export const postBulkPanelistSuccess = (payload) => ({
    type: POST_BULK_PANELIST_SUCCESS,
    payload,
});

export const postBulkPanelistFailure = (payload) => ({
    type: POST_BULK_PANELIST_FAILURE,
    payload,
});

export const addPanelist = (payload) => ({
    type: ADD_PANELIST,
    payload,
});

export const addPanelistSuccess = (payload) => ({
    type: ADD_PANELIST_SUCCESS,
    payload,
});

export const addPanelistFailure = (payload) => ({
    type: ADD_PANELIST_FAILURE,
    payload,
});

export const getPanelist = (payload) => ({
    type: GET_PANELIST,
    payload,
});

export const getPanelistSuccess = (payload) => ({
    type: GET_PANELIST_SUCCESS,
    payload,
});

export const getPanelistFailure = (payload) => ({
    type: GET_PANELIST_FAILURE,
    payload,
});

export const getAllPanelist = (payload) => ({
    type: GET_ALL_PANELISTS,
    payload,
});

export const getAllPanelistSuccess = (payload) => ({
    type: GET_ALL_PANELISTS_SUCCESS,
    payload,
});

export const getAllPanelistFailure = (payload) => ({
    type: GET_ALL_PANELISTS_FAILURE,
    payload,
});

export const updatePanelist = (payload) => ({
    type: EDIT_PANELIST,
    payload,
});

export const updatePanelistSuccess = (payload) => ({
    type: EDIT_PANELIST_SUCCESS,
    payload,
});

export const updatePanelistFailure = (payload) => ({
    type: EDIT_PANELIST_FAILURE,
    payload,
});

export const deletePanelist = (payload) => ({
    type: DELETE_PANELIST,
    payload,
});

export const deletePanelistSuccess = (payload) => ({
    type: DELETE_PANELIST_SUCCESS,
    payload,
});

export const deletePanelistFailure = (payload) => ({
    type: DELETE_PANELIST_FAILURE,
    payload,
});

export const updatePanelistStatus = (payload) => ({
    type: UPDATE_PANELIST_STATUS,
    payload,
});

export const updatePanelistStatusSuccess = (payload) => ({
    type: UPDATE_PANELIST_STATUS_SUCCESS,
    payload,
});

export const updatePanelistStatusFailure = (payload) => ({
    type: UPDATE_PANELIST_STATUS_FAILURE,
    payload,
});
