import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast } from '../../utils/helper';

import { CREATE_OR_UPDATE_CUSTOM_FIELD, DELETE_CUSTOM_FIELD, GET_ALL_CUSTOM_FIELDS } from '../action/types';
import {
    getAllCustomFieldsRequestSuccess,
    getAllCustomFieldsRequestFailure,
    deleteCustomFieldRequestSuccess,
    deleteCustomFieldRequestFailure,
    createOrUpdateCustomFieldRequestSuccess,
    createOrUpdateCustomFieldRequestFailure,
} from '../action';
import INTERVIEW from '../../utils/api/interview';

function* getAllCustomFieldsRequest(action) {
    try {
        let isSort = action?.payload?.sort ? `&sort=${action.payload.sort}` : '';
        let fieldMode = action?.payload?.fieldMode ? `&fieldMode=${action.payload.fieldMode}` : '';
        const res = yield INTERVIEW.get(`interviews/CustomFields/list?page=1&pageSize=100${fieldMode}${isSort}`);
        if (res.status === 200) {
            yield put(getAllCustomFieldsRequestSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getAllCustomFieldsRequestFailure());
    }
}

function* createOrUpdateCustomFieldRequest(action) {
    let res;
    try {
        if (action?.payload?.customFieldId) {
            res = yield INTERVIEW.put(`interviews/addCustomField`, action.payload?.customForm);
        } else {
            res = yield INTERVIEW.post(`interviews/addCustomField`, action.payload?.customForm);
        }
        if (res.status === 201) {
            if (action.payload?.customForm.customFieldId) {
                toast.success(<Toast msg={'Custom Field Updated Successfully'} />);
            } else {
                toast.success(<Toast msg={'Custom Field Added Successfully'} />);
            }
            yield call(action.payload.callback);
            yield put(createOrUpdateCustomFieldRequestSuccess(res.data?.result));
        }
    } catch (e) {
        yield put(createOrUpdateCustomFieldRequestFailure());

        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* deleteCustomFieldRequest(action) {
    try {
        const res = yield INTERVIEW.delete(`interviews/deleteCustomField/${action.payload.deleteId} `);
        if (res.status === 204) {
            yield put(deleteCustomFieldRequestSuccess());
            yield call(action.payload.callback);
            toast.success(<Toast msg={'Custom Field Deleted Successfully'} />);
        }
    } catch (e) {
        yield put(deleteCustomFieldRequestFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

export function* watchGetAllCustomFieldsRequestAPI() {
    yield takeEvery(GET_ALL_CUSTOM_FIELDS, getAllCustomFieldsRequest);
}
export function* watchDeleteCustomFieldRequestAPI() {
    yield takeEvery(DELETE_CUSTOM_FIELD, deleteCustomFieldRequest);
}
export function* watchCreateOrUpdateCustomFieldRequestAPI() {
    yield takeEvery(CREATE_OR_UPDATE_CUSTOM_FIELD, createOrUpdateCustomFieldRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetAllCustomFieldsRequestAPI(),
        watchDeleteCustomFieldRequestAPI(),
        watchCreateOrUpdateCustomFieldRequestAPI(),
    ]);
}
