import React from 'react';
import { capitalizeAll } from '../../utils/helper';

const RemarksContainer = ({
    label,
    name,
    value,
    error,
    onChange,
    isRequired,
    labelClass,
    errorClass,
    textClass,
    placeholder,
    onKeyDown,
    disabled,
}) => {
    return (
        <>
            <div>
                <label htmlFor={name} className={`c-label ${labelClass}`}>
                    {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
                </label>
            </div>
            <div>
                <textarea
                    className={`common-input ticket-text-area ${
                        disabled ? 'input-field-disabled' : ''
                    } ${textClass} ${error ? 'error' : ''}`}
                    name={name}
                    placeholder={capitalizeAll(placeholder)}
                    rows="3"
                    cols="50"
                    // maxLength="255"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={value}
                    disabled={disabled}
                ></textarea>
            </div>
            {error && <div className={`invalid ${errorClass}`}>{error}</div>}
        </>
    );
};

export default RemarksContainer;
