import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast, getAccessToken, localStorageEncrypt } from '../../utils/helper';

import {
    GET_SUB_USER,
    ADD_OR_EDIT_SUB_USER,
    DELETE_SUB_USER,
    ACTIVE_INACTIVE_SUBUSER,
    GET_ROLES,
    ADD_OR_EDIT_ROLE,
    DELETE_ROLES,
    ACTIVE_INACTIVE_ROLE,
    GET_PERMISSION,
    GET_USER_PERMISSION,
    GET_ROLE_BY_ID,
    GET_ALL_ROLES,
    GET_DROPDOWN_SUBUSER,
    GET_ALL_USERS,
    GET_RESOURCES,
    GET_EMPLOYER_ROLE,
    SAVE_EMPLOYER_ROLE,
    SUB_EMPLOYER_PASSWORD_RESET,
} from '../action/types';
import {
    getSubUserSuccess,
    getSubUserFailure,
    addOrEditSubUserSuccess,
    addOrEditSubUserFailure,
    deleteSubUserSuccess,
    deleteSubUserFailure,
    getSubUser,
    activeInactiveSubUserSuccess,
    activeInactiveSubUserFailure,
    getRolesSuccess,
    getRolesFailure,
    addOrEditRoleSuccess,
    addOrEditRoleFailure,
    deleteRoleSuccess,
    deleteRoleFailure,
    activeInactiveRoleSuccess,
    activeInactiveRoleFailure,
    getRoles,
    getPermissionSuccess,
    getPermissionFailure,
    getUserPermissionSuccess,
    getUserPermissionFailure,
    getRoleByIdSuccess,
    getRoleByIdFailure,
    getAllRolesSuccess,
    getAllRolesFailure,
    getDropdownSubUserSuccess,
    getDropdownSubUserFailure,
    getAllUsersSuccess,
    getAllUsersFailure,
    getResourcesSuccess,
    getResourcesFailure,
    getEmployerRoleSuccess,
    getEmployerRoleFailure,
    saveEmployerRoleSuccess,
    saveEmployerRoleFailure,
    subEmployerPasswordResetSuccess,
    subEmployerPasswordResetFailure,
} from '../action';
import SUBUSERS from '../../utils/api/subusers';
import UACL from '../../utils/api/uacl';
import PERMISSION from '../../utils/api/permission';
import { PageSize } from './../../utils/constant';

function* getSubUserRequest(action) {
    const isSearch = action.payload?.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';
    const isSort =
        action.payload.sort && action.payload.order ? `&sort=${action.payload.sort}&order=${action.payload.order}` : '';
    try {
        const res = yield SUBUSERS.get(
            `users/subusers?page=${action.payload.page}&pageSize=${action.payload.pageSize}${isSort}${isSearch}`
        );
        if (res.status === 200) {
            yield put(getSubUserSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getSubUserFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* getAllUsersRequest() {
    try {
        const res = yield SUBUSERS.get(`users/dashboard/dropdown`);
        if (res.status === 200) {
            yield put(getAllUsersSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getAllUsersFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* getDropdownSubUserRequest(action) {
    try {
        const res = yield SUBUSERS.get(`users/subusers/dropdown`);
        if (res.status === 200) {
            yield put(getDropdownSubUserSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getDropdownSubUserFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* addOrEditRoleRequest(action) {
    try {
        let res, message;
        if (action.payload?.id) {
            res = yield UACL.put(`users/roles/${action.payload?.id}`, action.payload?.data);
            message = 'Role updated successfully.';
        } else {
            res = yield UACL.post('users/roles', action.payload.data);
            message = 'Role added successfully.';
        }
        if (res.status === 201 || res.status === 200) {
            yield call(action.payload.callback);
            yield put(addOrEditRoleSuccess());
            yield toast.success(<Toast msg={message} />);
        }
    } catch (e) {
        yield put(addOrEditRoleFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* addOrEditSubUserRequest(action) {
    try {
        let res, message;
        if (action.payload?.id) {
            res = yield SUBUSERS.put(`users/subusers/${action.payload.id}`, action.payload?.data);
            message = 'Subuser Edit Successfully.';
        } else {
            res = yield SUBUSERS.post('users/subusers/newuser', action.payload.data);
            message = 'Subuser Added Successfully.';
        }
        if (res.status === 201 || res.status === 200) {
            yield call(action.payload.callback);
            yield put(addOrEditSubUserSuccess());
            yield toast.success(<Toast msg={message} />);
        }
    } catch (e) {
        yield put(addOrEditSubUserFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* deleteSubUserRequest(action) {
    try {
        const res = yield SUBUSERS.delete(`users/subusers/${action.payload.deleteId}`);
        if (res.status === 204) {
            const { callback, search, sort, order } = action.payload;
            yield call(callback);
            yield put(getSubUser({ page: 1, pageSize: PageSize, search, sort, order }));
            yield put(deleteSubUserSuccess());
            yield toast.success(<Toast msg={'Subuser deleted successfully.'} />);
        }
    } catch (e) {
        yield put(deleteSubUserFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* activeInactiveSubUserRequest(action) {
    try {
        const res = yield SUBUSERS.patch(`users/subusers/${action.payload.id}/status/${action.payload.status}`);
        if (res.status === 201) {
            const { status, search, sort, order } = action.payload;
            yield put(getSubUser({ page: 1, pageSize: PageSize, search, sort, order }));
            yield toast.success(<Toast msg={`Subuser ${status === 'active' ? 'Activated' : 'Deactivated'}`} />);
            yield put(activeInactiveSubUserSuccess());
        }
    } catch (e) {
        yield put(activeInactiveSubUserFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* getRolesRequest(action) {
    try {
        const res = yield UACL.get(`users/DropDownroles`);
        if (res.status === 200) {
            yield put(getRolesSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getRolesFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* getAllRolesRequest(action) {
    const isSort = action?.payload?.sort ? `&sort=${action?.payload?.sort}` : '';
    const isSearch = action?.payload?.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';
    try {
        const res = yield UACL.get(`users/getAllEmployerRoles?${isSort}${isSearch}`);
        if (res.status === 200) {
            yield put(getAllRolesSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getAllRolesFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* getRoleByIdRequest(action) {
    try {
        let roleId = action.payload?.roleId;
        const res = yield UACL.get(`users/roles/${roleId}`);
        if (res.status === 200) {
            // Api is with pagination
            yield put(getRoleByIdSuccess(res.data.result.data));
        }
    } catch (e) {
        yield put(getRoleByIdFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* deleteRoleRequest(action) {
    try {
        const res = yield UACL.delete(`users/roles/${action.payload?.deleteId}`);
        if (res.status === 204) {
            yield call(action.payload.callback);
            yield put(deleteRoleSuccess());
            yield toast.success(<Toast msg={'Role deleted successfully.'} />);
        }
    } catch (e) {
        yield put(deleteRoleFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* activeInactiveRoleRequest(action) {
    try {
        const res = yield UACL.patch(`/users/updateStatusEmployerRole/${action.payload?.id}`, action?.payload?.data);
        if (res.status === 201) {
            yield call(action.payload.callback);
            yield put(activeInactiveRoleSuccess());
            yield toast.success(
                <Toast msg={`Role ${action.payload.status.toLowerCase() === 'active' ? 'Activated' : 'Deactivated'}`} />
            );
        }
    } catch (e) {
        yield put(activeInactiveRoleFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* getPermissionRequest() {
    try {
        const res = yield UACL.get(`users/resource`);
        if (res.status === 200) {
            yield put(getPermissionSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPermissionFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* getUserPermissionRequest() {
    try {
        const res = yield PERMISSION.get(`users/permissions`, {
            headers: {
                Authorization: `Bearer ${getAccessToken}`,
            },
        });
        if (res.status === 200) {
            yield put(getUserPermissionSuccess(res.data.result));
            localStorageEncrypt('permissions', JSON.stringify(res.data.result));
        }
    } catch (e) {
        yield put(getUserPermissionFailure());
    }
}

// roles
function* getResourcesRequest(action) {
    try {
        const res = yield UACL.get(`users/${action.payload ? 'getResourses' : 'getDefaultResources'}`);
        if (res.status === 200) {
            yield put(getResourcesSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getResourcesFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}
function* getEmployerRoleRequest() {
    try {
        const res = yield UACL.get(`users/getAllEmployerRoles`);
        if (res.status === 200) {
            yield put(getEmployerRoleSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getEmployerRoleFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

function* saveEmployerRoleRequest(action) {
    try {
        let res, message;
        res = yield UACL.post(`/users/saveAndUpdateEmployerRole`, action.payload?.data);
        message = action?.payload?.data?.roleId ? 'Role updated successfully.' : 'Role Added Successfully';
        if (res.status === 201 || res.status === 200) {
            yield call(action.payload.callback);
            yield put(saveEmployerRoleSuccess());
            yield toast.success(<Toast msg={message} />);
        }
    } catch (e) {
        yield put(saveEmployerRoleFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}
function* subEmployerPasswordRequest(action) {
    try {
        const Id = action?.payload?.id ?? '';
        const res = yield UACL.get(`auth/subemployer/${Id}`);
        if (res.status === 200) {
            yield put(subEmployerPasswordResetSuccess());
            yield toast.success(<Toast msg={'Password reset mail sent successfully'} />);
        }
    } catch (e) {
        yield put(subEmployerPasswordResetFailure());
        const errorMessage = e?.response?.data?.message?.replace(/^Error:\s*/, '');
        yield errorMessage &&
            errorMessage !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={errorMessage} />, { toastId: errorMessage });
    }
}

export function* watchSubEmployerPasswordRestAPI() {
    yield takeEvery(SUB_EMPLOYER_PASSWORD_RESET, subEmployerPasswordRequest);
}

export function* watchGetResourcesAPI() {
    yield takeEvery(GET_RESOURCES, getResourcesRequest);
}
export function* watchSaveEmployerRoleAPI() {
    yield takeEvery(SAVE_EMPLOYER_ROLE, saveEmployerRoleRequest);
}

export function* watchGetEmployerRoleAPI() {
    yield takeEvery(GET_EMPLOYER_ROLE, getEmployerRoleRequest);
}

export function* watchGetAllUsersAPI() {
    yield takeEvery(GET_ALL_USERS, getAllUsersRequest);
}

export function* watchGetSubUserAPI() {
    yield takeEvery(GET_SUB_USER, getSubUserRequest);
}

export function* watchAddOrEditSubUserAPI() {
    yield takeEvery(ADD_OR_EDIT_SUB_USER, addOrEditSubUserRequest);
}

export function* watchDeleteSubUserAPI() {
    yield takeEvery(DELETE_SUB_USER, deleteSubUserRequest);
}

export function* watchActiveInActiveSubUserAPI() {
    yield takeEvery(ACTIVE_INACTIVE_SUBUSER, activeInactiveSubUserRequest);
}

export function* watchDeleteRoleAPI() {
    yield takeEvery(DELETE_ROLES, deleteRoleRequest);
}

export function* watchGetRolesAPI() {
    yield takeEvery(GET_ROLES, getRolesRequest);
}

export function* watchActiveInActiveRoleAPI() {
    yield takeEvery(ACTIVE_INACTIVE_ROLE, activeInactiveRoleRequest);
}

export function* watchGetPermissionAPI() {
    yield takeEvery(GET_PERMISSION, getPermissionRequest);
}

export function* watchGetUserPermissionAPI() {
    yield takeEvery(GET_USER_PERMISSION, getUserPermissionRequest);
}

export function* watchAddOrEditRoleAPI() {
    yield takeEvery(ADD_OR_EDIT_ROLE, addOrEditRoleRequest);
}

export function* watchGetRoleByIdAPI() {
    yield takeEvery(GET_ROLE_BY_ID, getRoleByIdRequest);
}

export function* watchGetAllRolesAPI() {
    yield takeEvery(GET_ALL_ROLES, getAllRolesRequest);
}
export function* watchGetDropdownSubUserRequestAPI() {
    yield takeEvery(GET_DROPDOWN_SUBUSER, getDropdownSubUserRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetSubUserAPI(),
        watchAddOrEditSubUserAPI(),
        watchDeleteSubUserAPI(),
        watchActiveInActiveSubUserAPI(),
        watchGetRolesAPI(),
        watchDeleteRoleAPI(),
        watchActiveInActiveRoleAPI(),
        watchGetPermissionAPI(),
        watchGetDropdownSubUserRequestAPI(),
        watchGetUserPermissionAPI(),
        watchAddOrEditRoleAPI(),
        watchGetRoleByIdAPI(),
        watchGetAllRolesAPI(),
        watchGetAllUsersAPI(),
        watchGetResourcesAPI(),
        watchSaveEmployerRoleAPI(),
        watchGetEmployerRoleAPI(),
        watchSubEmployerPasswordRestAPI(),
    ]);
}
