import React, { memo } from 'react';
import file from '../../assets/images/file.svg';

const FileContainerComponent = memo(({ labelClass, inputClass, label, fileName, onClick }) => {
    return (
        <>
            {label && (
                <label htmlFor={fileName} className={`c-label ${labelClass}`}>
                    {label} <span className="asterisk f-12">&#42;</span>
                </label>
            )}
            <div
                className={`position-relative file-input bg-white d-flex align-items-center justify-content-end rounded`}
                onClick={onClick}
            >
                <div className={`${inputClass} real-file-input position-relative`}>hello</div>
                <img src={file} alt="file" className="file h-75 position-absolute img-fluid" />
            </div>
            <span className="upload-type f-ph f-reg">*supported file types are .pdf, .docx, .doc</span>
        </>
    );
});

export default FileContainerComponent;
