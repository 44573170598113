import React from 'react';
import { all, call, takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast } from '../../utils/helper';

import { TOKEN_VERIFY } from '../action/types';
import { tokenVerifySuccess, tokenVerifyFailure } from '../action';
import AUTH from '../../utils/api/auth';

function* tokenVerifyRequest(action) {
    try {
        const res = yield AUTH.post(`oauth/verify?token=${action.payload.token}`);
        if (res.status === 201) {
            yield call(action.payload.callback);
            yield put(tokenVerifySuccess());
            yield toast.success(<Toast msg="Email Verification Successful." />);
        } else {
            yield put(tokenVerifyFailure());
            yield toast.error(<Toast msg="Something went wrong. Please contact to admin." />, {
                toastId: 'Something went wrong. Please contact to admin.',
            });
        }
    } catch (e) {
        yield put(tokenVerifyFailure());
        yield toast.error(<Toast msg="Something went wrong. Please contact to admin." />, {
            toastId: 'Something went wrong. Please contact to admin.',
        });
    }
}
export function* watchTokenVerifyAPI() {
    yield takeEvery(TOKEN_VERIFY, tokenVerifyRequest);
}

export default function* rootSaga() {
    yield all([watchTokenVerifyAPI()]);
}
