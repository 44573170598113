import { React, useState, useRef } from 'react';
import dropArrow from '../../assets/images/arrow-drop-white.svg';
import { useOutsideAlerter } from '../../utils/helper';
const AddPanelist = ({ setIsBulkUpload, setSideForm, setForm, setEditData, setIsEdit }) => {
    const [drop, setDrop] = useState(false);
    const ref = useRef();
    useOutsideAlerter(ref, () => setDrop(!drop));

    return (
        <div className="quick-create-container">
            <button className="f-l f-sem-bol quick-create-btn d-center rounded" onClick={() => setDrop(!drop)}>
                <span>Add Panelist</span>
                <img src={dropArrow} className={'quick-arrow'} />
            </button>
            {drop && (
                <div className="quick-dropdown rounded f-12 f-sem-bol" ref={ref}>
                    <div
                        className="quick-drop-option"
                        onClick={() => {
                            setSideForm(true);
                            setDrop(false);
                            setForm({
                                product: '',
                                name: '',
                                email: '',
                                department: '',
                                phone: '',
                                designation: '',
                                experience: '',
                                skills: [],
                                customSkills: [],
                                domain: '',
                                format: '',
                            });
                            setEditData('');
                            setIsEdit(false);
                        }}
                    >
                        Add Panelist
                    </div>
                    <div className="quick-drop-divider"></div>
                    <div
                        className="quick-drop-option"
                        onClick={() => {
                            setDrop(false);
                            setIsBulkUpload(true);
                        }}
                    >
                        Upload Bulk Panelist
                    </div>
                    <div className="quick-drop-divider"></div>
                </div>
            )}
        </div>
    );
};

export default AddPanelist;
