import {
    SUPPORT_REQUEST,
    SUPPORT_REQUEST_SUCCESS,
    SUPPORT_REQUEST_FAILURE,
    CALL_BACK_REQUEST,
    CALL_BACK_REQUEST_SUCCESS,
    CALL_BACK_REQUEST_FAILURE,
    GET_SUPPORT_TICKET,
    GET_SUPPORT_TICKET_SUCCESS,
    GET_SUPPORT_TICKET_FAILURE,
    CLOSE_SUPPORT_TICKET,
    CLOSE_SUPPORT_TICKET_SUCCESS,
    CLOSE_SUPPORT_TICKET_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    supportTickets: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUPPORT_REQUEST:
            return { ...state, loading: true };
        case SUPPORT_REQUEST_SUCCESS:
            return { ...state, loading: false };
        case SUPPORT_REQUEST_FAILURE:
            return { ...state, loading: false };
        case CALL_BACK_REQUEST:
            return { ...state, loading: true };
        case CALL_BACK_REQUEST_SUCCESS:
            return { ...state, loading: false };
        case CALL_BACK_REQUEST_FAILURE:
            return { ...state, loading: false };
        case GET_SUPPORT_TICKET:
            return { ...state, loading: true };
        case GET_SUPPORT_TICKET_SUCCESS:
            return { ...state, loading: false, supportTickets: action.payload };
        case GET_SUPPORT_TICKET_FAILURE:
            return { ...state, loading: false };
        case CLOSE_SUPPORT_TICKET:
            return { ...state, loading: true };
        case CLOSE_SUPPORT_TICKET_SUCCESS:
            return { ...state, loading: false };
        case CLOSE_SUPPORT_TICKET_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
