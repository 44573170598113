import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    CONFIGURATION_AUTOINVITE,
    CONFIGURATION_AUTOINVITE_SUCCESS,
    CONFIGURATION_AUTOINVITE_FAILURE,
    GET_CONFIGURATION_AUTO_INVITE,
    GET_CONFIGURATION_AUTO_INVITE_SUCCESS,
    GET_CONFIGURATION_AUTO_INVITE_FAILURE,
    FETCH_API_TOKEN,
    FETCH_API_TOKEN_SUCCESS,
    FETCH_API_TOKEN_FAILURE,
    FETCH_API_KEY,
    FETCH_API_KEY_SUCCESS,
    FETCH_API_KEY_FAILURE,
    CONFIGURATION_PANELISTSLOTS,
    CONFIGURATION_PANELISTSLOTS_SUCCESS,
    CONFIGURATION_PANELISTSLOTS_FAILURE,
    EMAIL_NOTIFICATION_CONFIG,
    EMAIL_NOTIFICATION_CONFIG_SUCCESS,
    EMAIL_NOTIFICATION_CONFIG_FAILURE,
    GET_EMAIL_NOTIFICATION_CONFIG,
    GET_EMAIL_NOTIFICATION_CONFIG_SUCCESS,
    GET_EMAIL_NOTIFICATION_CONFIG_FAILURE,
} from './types';

export const fetchApiKey = () => ({
    type: FETCH_API_KEY,
});
export const fetchApiKeySuccess = (payload) => ({
    type: FETCH_API_KEY_SUCCESS,
    payload,
});
export const fetchApiKeyFailure = () => ({
    type: FETCH_API_KEY_FAILURE,
});

export const fetchApiToken = () => ({
    type: FETCH_API_TOKEN,
});
export const fetchApiTokenSuccess = (payload) => ({
    type: FETCH_API_TOKEN_SUCCESS,
    payload,
});
export const fetchApiTokenFailure = () => ({
    type: FETCH_API_TOKEN_FAILURE,
});

export const loginUser = (payload) => ({
    type: LOGIN_USER,
    payload,
});
export const loginUserSuccess = (payload) => ({
    type: LOGIN_USER_SUCCESS,
    payload,
});
export const loginUserFailure = (payload) => ({
    type: LOGIN_USER_FAILURE,
    payload,
});

export const forgotPassword = (payload) => ({
    type: FORGOT_PASSWORD,
    payload,
});
export const forgotPasswordSuccess = () => ({
    type: FORGOT_PASSWORD_SUCCESS,
});
export const forgotPasswordFailure = () => ({
    type: FORGOT_PASSWORD_FAILURE,
});

export const resetPassword = (payload) => ({
    type: RESET_PASSWORD,
    payload,
});
export const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS,
});
export const resetPasswordFailure = () => ({
    type: RESET_PASSWORD_FAILURE,
});
export const changePassword = (payload) => ({
    type: CHANGE_PASSWORD,
    payload,
});
export const changePasswordSuccess = (payload) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload,
});
export const changePasswordFailure = () => ({
    type: CHANGE_PASSWORD_FAILURE,
});
export const configurationAutoInvite = (payload) => ({
    type: CONFIGURATION_AUTOINVITE,
    payload,
});
export const configurationAutoInviteSuccess = (payload) => ({
    type: CONFIGURATION_AUTOINVITE_SUCCESS,
    payload,
});
export const configurationAutoInviteFailure = () => ({
    type: CONFIGURATION_AUTOINVITE_FAILURE,
});

export const getConfigurationAutoInvite = (payload) => ({
    type: GET_CONFIGURATION_AUTO_INVITE,
    payload,
});
export const getConfigurationAutoInviteSuccess = (payload) => ({
    type: GET_CONFIGURATION_AUTO_INVITE_SUCCESS,
    payload,
});
export const getConfigurationAutoInviteFailure = () => ({
    type: GET_CONFIGURATION_AUTO_INVITE_FAILURE,
});

export const configurationPanelistSlots = (payload) => ({
    type: CONFIGURATION_PANELISTSLOTS,
    payload,
});
export const configurationPanelistSlotsSuccess = (payload) => ({
    type: CONFIGURATION_PANELISTSLOTS_SUCCESS,
    payload,
});
export const configurationPanelistSlotsFailure = () => ({
    type: CONFIGURATION_PANELISTSLOTS_FAILURE,
});

export const logoutUser = () => ({
    type: LOGOUT_USER,
});

export const logoutUserSuccess = () => ({
    type: LOGOUT_USER_SUCCESS,
});

export const logoutUserFailure = () => ({
    type: LOGOUT_USER_FAILURE,
});

export const emailNotificationConfig = (payload) => ({
    type: EMAIL_NOTIFICATION_CONFIG,
    payload,
});
export const emailNotificationConfigSuccess = (payload) => ({
    type: EMAIL_NOTIFICATION_CONFIG_SUCCESS,
    payload,
});
export const emailNotificationConfigFailure = () => ({
    type: EMAIL_NOTIFICATION_CONFIG_FAILURE,
});

export const getEmailNotificationConfig = (payload) => ({
    type: GET_EMAIL_NOTIFICATION_CONFIG,
    payload,
});
export const getEmailNotificationConfigSuccess = (payload) => ({
    type: GET_EMAIL_NOTIFICATION_CONFIG_SUCCESS,
    payload,
});
export const getEmailNotificationConfigFailure = () => ({
    type: GET_EMAIL_NOTIFICATION_CONFIG_FAILURE,
});
