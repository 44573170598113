import {
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAILURE,
    GET_PLANS_AND_PRICING,
    GET_PLANS_AND_PRICING_SUCCESS,
    GET_PLANS_AND_PRICING_FAILURE,
    UPDATE_PAYMENT_INFORMATION,
    UPDATE_PAYMENT_INFORMATION_SUCCESS,
    UPDATE_PAYMENT_INFORMATION_FAILURE,
    GET_ORDER_LIST,
    GET_ORDER_LIST_SUCCESS,
    GET_ORDER_LIST_FAILURE,
    GET_BILLING_INFORMATION_SUCCESS,
    GET_BILLING_INFORMATION_FAILURE,
    GET_BILLING_INFORMATION,
} from './types';

export const getProductList = () => ({
    type: GET_PRODUCT_LIST,
});
export const getProductListSuccess = (payload) => ({
    type: GET_PRODUCT_LIST_SUCCESS,
    payload,
});
export const getProductListFailure = () => ({
    type: GET_PRODUCT_LIST_FAILURE,
});

export const getPlansAndPricingList = (payload) => ({
    type: GET_PLANS_AND_PRICING,
    payload,
});
export const getPlansAndPricingListSuccess = (payload) => ({
    type: GET_PLANS_AND_PRICING_SUCCESS,
    payload,
});
export const getPlansAndPricingListFailure = () => ({
    type: GET_PLANS_AND_PRICING_FAILURE,
});

export const updatePaymentInformation = (payload) => ({
    type: UPDATE_PAYMENT_INFORMATION,
    payload,
});
export const updatePaymentInformationSuccess = () => ({
    type: UPDATE_PAYMENT_INFORMATION_SUCCESS,
});
export const updatePaymentInformationFailure = () => ({
    type: UPDATE_PAYMENT_INFORMATION_FAILURE,
});

export const getOrderList = (payload) => ({
    type: GET_ORDER_LIST,
    payload,
});

export const getOrderListSuccess = (payload) => ({
    type: GET_ORDER_LIST_SUCCESS,
    payload,
});

export const getOrderListFailure = () => ({
    type: GET_ORDER_LIST_FAILURE,
});

export const getBillingInformation = (payload) => ({
    type: GET_BILLING_INFORMATION,
    payload,
});

export const getBillingInformationSuccess = (payload) => ({
    type: GET_BILLING_INFORMATION_SUCCESS,
    payload,
});

export const getBillingInformationFailure = (payload) => ({
    type: GET_BILLING_INFORMATION_FAILURE,
    payload,
});
