import React from 'react';
import leftArrow from '../../assets/images/arrow_left.svg';
import rightArrow from '../../assets/images/arrow_right.svg';
import { usePagination, DOTS } from '../../utils/usePagination';

const Pagination = (props) => {
    const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    // if (currentPage === 0 || paginationRange.length < 2) {
    //   return null;
    // }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <div className="row mt-4 mx-0">
            <div className="col-12 col-md-4">
                Showing {currentPage * pageSize - pageSize + 1} to{' '}
                {currentPage * pageSize < totalCount ? currentPage * pageSize : totalCount} of {totalCount} entries
            </div>
            <div className="col-12 col-md-8 mt-2 mt-md-0">
                <ul className={'pagination-container float-md-end'}>
                    <li className={`pagination-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={onPrevious}>
                        <img src={leftArrow} alt="left-arrow" className="pagination-arrow" />
                    </li>
                    {paginationRange?.map((pageNumber, index) => {
                        if (pageNumber === DOTS) {
                            return (
                                <li className="pagination-item dots" key={`l${index}`}>
                                    &#8230;
                                </li>
                            );
                        }
                        return (
                            <li
                                className={`pagination-item ${currentPage === pageNumber ? 'selected' : ''}`}
                                onClick={() => onPageChange(pageNumber)}
                                key={pageNumber}
                            >
                                {pageNumber}
                            </li>
                        );
                    })}
                    <li className={`pagination-item ${currentPage === lastPage ? 'disabled' : ''}`} onClick={onNext}>
                        <img src={rightArrow} alt="right-arrow" className="pagination-arrow" />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pagination;
