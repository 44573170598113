import axios from 'axios';
import { redirectPermission, generateUrl, getAccessToken } from '../helper';

const uacl = axios.create({
    baseURL: generateUrl('user_backend'),
});

uacl.interceptors.request.use(
    function (config) {
        config.headers.Authorization = `Bearer ${getAccessToken}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

uacl.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            redirectPermission();
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export default uacl;
