import React, { memo } from 'react';

const Select = memo(
    ({
        labelClass,
        selectClass,
        optionClass,
        options,
        disabled,
        setValue,
        error,
        selectValue,
        name,
        label,
        isRequired,
    }) => {
        return (
            <>
                {label && (
                    <label htmlFor={name} className={`c-label ${labelClass}`}>
                        {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
                    </label>
                )}
                <div className="position-relative">
                    <select
                        className={`border ${selectClass} form-select ${error ? 'error' : ''}`}
                        disabled={disabled}
                        onChange={setValue}
                        value={selectValue}
                        name={name}
                        data-live-search="true"
                    >
                        <option disabled className={`${optionClass}`}>
                            Please Select
                        </option>
                        {options &&
                            options?.map((option, index) => (
                                <option
                                    key={index}
                                    data-token={option?.label}
                                    value={option?.value}
                                    className={optionClass}
                                    selected={selectValue === option?.value}
                                >
                                    {option?.label}
                                </option>
                            ))}
                    </select>
                    {error && <div className="invalid">{error}</div>}
                </div>
            </>
        );
    }
);

export default Select;
