import {
    ADD_PANELIST,
    ADD_PANELIST_SUCCESS,
    ADD_PANELIST_FAILURE,
    GET_PANELIST,
    GET_PANELIST_SUCCESS,
    GET_PANELIST_FAILURE,
    EDIT_PANELIST,
    EDIT_PANELIST_SUCCESS,
    EDIT_PANELIST_FAILURE,
    DELETE_PANELIST,
    DELETE_PANELIST_SUCCESS,
    DELETE_PANELIST_FAILURE,
    UPDATE_PANELIST_STATUS,
    UPDATE_PANELIST_STATUS_SUCCESS,
    UPDATE_PANELIST_STATUS_FAILURE,
    POST_BULK_PANELIST,
    POST_BULK_PANELIST_SUCCESS,
    POST_BULK_PANELIST_FAILURE,
    NOTIFY_PANELIST,
    NOTIFY_PANELIST_SUCCESS,
    NOTIFY_PANELIST_FAILURE,
    GET_ALL_PANELISTS,
    GET_ALL_PANELISTS_SUCCESS,
    GET_ALL_PANELISTS_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    panelistDetails: [],
    notifyPanelist: null,
    timeStamp: null,
    allPanelistDetails: [],
    bulkPanelistData: null,
    bulkPanelistLoading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_BULK_PANELIST:
            return { ...state, bulkPanelistLoading: true, bulkPanelistData: null };
        case POST_BULK_PANELIST_SUCCESS:
            return { ...state, bulkPanelistLoading: false, bulkPanelistData: action.payload };
        case POST_BULK_PANELIST_FAILURE:
            return { ...state, bulkPanelistLoading: false, bulkPanelistData: null };
        case NOTIFY_PANELIST:
            return { ...state, loading: true };
        case NOTIFY_PANELIST_SUCCESS:
            return { ...state, loading: false, notifyPanelist: action.payload };
        case NOTIFY_PANELIST_FAILURE:
            return { ...state, loading: false };
        case ADD_PANELIST:
            return { ...state, loading: true };
        case ADD_PANELIST_SUCCESS:
            return { ...state, loading: false };
        case ADD_PANELIST_FAILURE:
            return { ...state, loading: false };
        case GET_PANELIST:
            return { ...state, loading: true };
        case GET_PANELIST_SUCCESS:
            return {
                ...state,
                loading: false,
                panelistDetails: action.payload,
            };
        case GET_PANELIST_FAILURE:
            return { ...state, loading: false };
        case GET_ALL_PANELISTS:
            return { ...state, loading: true };
        case GET_ALL_PANELISTS_SUCCESS:
            return {
                ...state,
                loading: false,
                timeStamp: state.timeStamp > action.payload.timeStamp ? state.timeStamp : action.payload.timeStamp,
                allPanelistDetails:
                    state.timeStamp > action.payload.timeStamp ? state.allPanelistDetails : action.payload,
            };
        case GET_ALL_PANELISTS_FAILURE:
            return { ...state, loading: false };
        case EDIT_PANELIST:
            return { ...state, loading: true };
        case EDIT_PANELIST_SUCCESS:
            return { ...state, loading: false };
        case EDIT_PANELIST_FAILURE:
            return { ...state, loading: false };
        case DELETE_PANELIST:
            return { ...state, loading: true };
        case DELETE_PANELIST_SUCCESS:
            return { ...state, loading: false };
        case DELETE_PANELIST_FAILURE:
            return { ...state, loading: false };
        case UPDATE_PANELIST_STATUS:
            return { ...state, loading: true };
        case UPDATE_PANELIST_STATUS_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_PANELIST_STATUS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
