import React, { memo, useState, useEffect, useRef } from 'react';
import { useOutsideAlerter } from '../../utils/helper';
import filter from '../../assets/images/filter.svg';
import Select from 'react-select';

const FilterBtn = memo(({ setCurrentPage, filterType, setIsFilterData, DropdownSubUserList, tab, isFilterData }) => {
    const [showFilter, setShowFilter] = useState(false);
    const ref = useRef();
    useOutsideAlerter(ref, () => setShowFilter(false));
    const order = {
        'Newest to Oldest': '_id desc',
        'Oldest to Newest': '_id asc',
    };
    order[filterType === 'interview' ? 'Name (A - Z)' : 'Job Title (A - Z)'] =
        filterType === 'interview' ? 'candidateName asc' : 'title asc';
    const feedback = {
        'Excellent: >= 9': 'excellent',
        'Very Good: 8': 'very_good',
        'Good: 7': 'good',
        'Above Average 6': 'above_average',
        'Average: 5': 'average',
        'Poor: 3 to 4': 'poor',
        'Very Poor: <= 2': 'very_poor',
    };

    const [range, setRange] = useState('0');
    const [filterStates, setFilterStates] = useState({
        sort: '',
        status: filterType === 'interview' ? (tab === 3 ? 'completed' : tab === 2 ? 'scheduled' : '') : '',
        from: '',
        to: '',
        experience: '',
        feedback: '',
        requestedSlots: false,
        createdBy: '',
    });

    const [filterStatus, setFilterStatus] = useState(
        filterStates?.filter?.status ? filterStates?.filter?.status?.split(',') : ''
    );

    useEffect(() => {
        const element = document.getElementById('rs-bullet');
        if (element) {
            document.getElementById('rs-bullet').style.left = `${(range * 80) / 40}%`;
        }
    }, [range]);

    const handleShowFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleFilterChange = (e, inputType, inputName) => {
        let filterStatesData;
        if (inputName === 'range') {
            setRange(e.target.value);
            setFilterStates({ ...filterStates, [inputType]: e.target.value });
            filterStatesData = { ...filterStates, [inputType]: e.target.value };
        }

        if (!e.target.checked && inputName !== 'range') {
            const currentStates = {
                ...filterStates,
            };
            if (inputType === 'status') {
                currentStates.status = '';
            }
            setFilterStates(currentStates);
            filterStatesData = { ...filterStates };
            if (inputType === 'status') {
                filterStatesData.status = '';
            } else {
                setFilterStates({ ...filterStates, [inputType]: e.target.value });
                filterStatesData = { ...filterStates, [inputType]: e.target.value };
            }
        } else {
            setFilterStates({ ...filterStates, [inputType]: e.target.value });
            filterStatesData = { ...filterStates, [inputType]: e.target.value };
        }
        if (inputName === 'requestedSlots') {
            setFilterStates({
                ...filterStates,
                requestedSlots: !filterStates.requestedSlots,
            });
            filterStatesData = {
                ...filterStates,
                requestedSlots: !filterStates.requestedSlots,
            };
        }
        if (inputType === 'createdBy') {
        }
        setCurrentPage(1);
        if (filterType === 'interview') {
            filterStatesData.status = filterStatesData.status
                ? filterStatesData.status
                : tab === 3
                  ? 'completed'
                  : tab === 2
                    ? 'scheduled'
                    : '';
            setIsFilterData(filterStatesData);
        } else {
            setIsFilterData(filterStatesData);
        }
    };
    const handleFilterStatusChange = (value) => {
        const index = filterStatus.indexOf(value);
        let finalStatus;
        if (index === -1) {
            finalStatus = [...filterStatus, value];
            setFilterStatus([...filterStatus, value]);
        } else {
            const newStatus = [...filterStatus];
            newStatus.splice(index, 1);
            finalStatus = newStatus;
            setFilterStatus(newStatus);
        }
        setFilterStates((prev) => ({
            ...prev,
            status: finalStatus.join(','),
        }));
        setIsFilterData({
            ...filterStates,
            status: finalStatus.join(', '),
        });
    };
    const handleCheckboxKeyDown = (e, value) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleFilterStatusChange(value);
        }
    };
    const handleSelectChange = (e, name) => {
        let filterStatesData;
        setFilterStates({ ...filterStates, [name]: e.value });
        filterStatesData = { ...filterStates, [name]: e.value };
        setIsFilterData(filterStatesData);
    };
    const handleClearFilter = () => {
        let filterStatesData = {
            sort: '',
            status: filterType === 'interview' ? (tab === 3 ? 'completed' : tab === 2 ? 'scheduled' : '') : '',
            from: '',
            to: '',
            experience: '',
            feedback: '',
            requestedSlots: false,
            createdBy: '',
        };
        setShowFilter(!showFilter);
        setFilterStates(filterStatesData);
        setRange('0');
        setIsFilterData(filterStatesData);
    };

    useEffect(() => {
        if (filterType === 'interview') {
            setFilterStates({
                sort: '',
                status: tab === 3 ? 'completed' : tab === 2 ? 'scheduled' : '',
                from: '',
                to: '',
                experience: '',
                feedback: '',
                requestedSlots: false,
                createdBy: '',
            });
        }
    }, [tab, filterType]);

    const getLabelOptions = () => {
        if (tab === 3) {
            return [
                { label: 'Shortlisted', value: 'shortlisted' },
                { label: 'Rejected', value: 'rejected' },
                { label: 'No Show', value: 'noshow' },
                { label: 'Hold', value: 'hold' },
            ];
        }
        return [
            { label: 'Completed', value: 'completed' },
            { label: 'Scheduled', value: 'scheduled' },
            { label: 'Pending', value: 'pending' },
            {
                label: 'Cancelled',
                value: 'cancelled',
            },
            { label: 'Hold', value: 'hold' },
        ];
    };

    return (
        <div className="filter-btn-container position-relative mx-1">
            <button className="filter-btn d-center" onClick={handleShowFilter} title="Filter">
                <img src={filter} alt="filter-btn" className="filter-img img-fluid" />
            </button>
            {showFilter && (
                <div className="position-absolute filter-btn-popup input-shadow rounded f-reg" ref={ref}>
                    <div className="date-container f-reg">
                        <label htmlFor="" className={'f-l'}>
                            <span className="f-sem-bol">From Date</span>
                            <input
                                type="date"
                                max={filterStates?.to ? filterStates?.to : ''}
                                value={filterStates?.from ? filterStates?.from : ''}
                                onChange={(e) => handleFilterChange(e, 'from')}
                            />
                        </label>
                        <label htmlFor="" className={'f-l'}>
                            <span className="f-sem-bol">To Date</span>
                            <input
                                type="date"
                                min={filterStates?.from ? filterStates?.from : ''}
                                value={filterStates?.to ? filterStates?.to : ''}
                                onChange={(e) => handleFilterChange(e, 'to')}
                            />
                        </label>
                    </div>
                    {filterType === 'interview' && tab !== 2 && (
                        <>
                            <div className="f-med title f-l">Labels</div>
                            {getLabelOptions().map((option, index) => (
                                <label htmlFor={option.value} key={index} className="f-l d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        value={option.value}
                                        checked={filterStatus.includes(option.value)}
                                        onChange={() => handleFilterStatusChange(option.value)}
                                        tabIndex={0} // tabIndex for keyboard accessibility
                                        onKeyDown={(e) => handleCheckboxKeyDown(e, option.value)}
                                    />
                                    <span>{option.label}</span>
                                </label>
                            ))}
                            {tab === 1 && (
                                <label htmlFor={'requestedSlots'} className="f-l d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        value={filterStates?.requestedSlots}
                                        checked={filterStates?.requestedSlots}
                                        onChange={(e) => handleFilterChange(e, 'requestedSlots', 'requestedSlots')}
                                    />
                                    <span>Slot Requested</span>
                                </label>
                            )}
                        </>
                    )}
                    <div className="range-box">
                        <div className="f-med title f-l">Experience</div>
                        <div className="range-slider f-l w-100">
                            <div
                                id="rs-bullet"
                                className="rs-label f-sem-bol d-center"
                                style={{ left: `${(range * 80) / 40}%` }}
                            >
                                {range}
                            </div>
                            <input
                                id="rs-range-line"
                                className="rs-range"
                                type="range"
                                min={0}
                                max={40}
                                value={range}
                                onChange={(e) => handleFilterChange(e, 'experience', 'range')}
                            />
                        </div>
                        <div className="box-minmax f-ph f-sem-bol">
                            <span>0yr</span>
                            <span>40yrs</span>
                        </div>
                    </div>
                    <div className="f-med title f-l">Posted By</div>
                    <div className="subuser-dropdown-container">
                        <Select
                            className="f-12 f-med subuser-dropdown"
                            label="Created By"
                            labelClass={'my-1 f-l f-bol'}
                            inputClass="f-l f-reg rounded input-shadow"
                            onChange={(e) => handleSelectChange(e, 'createdBy')}
                            options={DropdownSubUserList}
                            value={
                                filterStates?.createdBy &&
                                DropdownSubUserList &&
                                DropdownSubUserList.find((itm) => itm?.value === filterStates?.createdBy)
                            }
                            borderNone
                        />
                    </div>
                    <div className="f-med title f-l">Order By</div>
                    {Object.entries(order).map((data, index) => (
                        <label htmlFor={data[1]} key={index} className={'f-l d-flex align-items-center'}>
                            <input
                                type="radio"
                                value={data[1]}
                                checked={filterStates?.sort === data[1]}
                                onChange={(e) => handleFilterChange(e, 'sort')}
                            />
                            <span>{data[0]}</span>
                        </label>
                    ))}
                    {filterType === 'interview' && tab === 3 && (
                        <>
                            <div className="f-med title f-l">Feedback Rating</div>
                            {Object.entries(feedback).map((data, index) => (
                                <label htmlFor={data[1]} key={index} className={'f-l d-flex align-items-center'}>
                                    <input
                                        type="radio"
                                        value={data[1]}
                                        checked={filterStates?.feedback === data[1]}
                                        onChange={(e) => handleFilterChange(e, 'feedback')}
                                    />
                                    <span>{data[0]}</span>
                                </label>
                            ))}
                        </>
                    )}
                    <button
                        className="filter-clear-btn d-flex align-items-center justify-content-center"
                        onClick={handleClearFilter}
                    >
                        Clear Filter
                    </button>
                </div>
            )}
        </div>
    );
});

export default FilterBtn;
