import React, { memo, useState } from 'react';
import noDataImg from '../../assets/images/no-data-found.svg';
import twoArrow from '../../assets/images/2arrows.svg';
import debounce from 'lodash/debounce';

const NoRecordFound = memo(({ type }) => {
    const [sortBy, setSortBy] = useState('asc');
    const [sort, setSort] = useState('');
    const handleSorting = (key, sortBy) => {
        setSort(key + ' ' + sortBy);
    };
    const header = [
        {
            name: 'Select',
            width: 'w-7-5',
            key: '_id',
        },
        {
            name: 'Panelist Id',
            width: 'w-12-5',
            key: 'code',
        },
        {
            name: 'Name',
            width: 'w-12-5',
            key: 'name',
        },
        {
            name: 'Email',
            width: 'w-15',
            key: 'email',
        },
        {
            name: 'Designation',
            width: 'w-15',
            key: 'designation',
        },
        {
            name: 'Status',
            width: 'w-10',
            key: 'status',
        },
        {
            name: 'Skills',
            width: 'w-20',
            key: '',
        },
        {
            name: 'Slot Status',
            width: 'w-12-5',
            key: 'slotStatus',
        },
        {
            name: 'Slot Details',
            width: 'w-10',
            key: 'slotDetails',
        },
        {
            name: 'Action',
            width: 'w-7-5',
            key: 'action',
        },
    ];
    return (
        <div className="no-record-found d-center flex-column">
            {type === 'create-panelist' ? (
                <div className="panels-table w-100">
                    <div className="panels-header rounded mb-1 py-1">
                        {header.map((data, index) => (
                            <div key={index} className={`f-l f-bol header-content d-center ${data.width}`}>
                                <span>{data.name}</span>
                                {data.key !== 'action' &&
                                data.key !== '_id' &&
                                data.key !== '' &&
                                data.key !== 'slotStatus' &&
                                data.key !== 'slotDetails' ? (
                                    <img
                                        src={twoArrow}
                                        alt="double-arrow"
                                        className="double-arrow cursor-pointer"
                                        onClick={debounce(() => {
                                            setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
                                            handleSorting(data.key, sortBy);
                                        }, 300)}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        ))}
                    </div>
                    <img src={noDataImg} alt="no data image" className="no-data-image" />
                    <div className="f-20 f-bol text-center">No Record Found</div>
                </div>
            ) : (
                <>
                    <img src={noDataImg} alt="no data image" className="no-data-image" />
                    <div className="f-20 f-bol text-center">No Record Found</div>
                </>
            )}
        </div>
    );
});

export default NoRecordFound;
