import React, { useState } from 'react';
import { RemarksContainer } from '.';
import deleteLogo from '../../assets/images/delete-logo.svg';
import Button from '../CommonComponent/Button';

const ConfirmCancelPopUp = ({ handleCancel, handleDelete, content, firstButton, secondButton, setReason, reason }) => {
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        let inputValue = e.target.value;
        if (error) setError('');
        if (inputValue.length > 200) {
            inputValue = inputValue.substring(0, 200);
            setError('Maximum limit allowed is up to 200 characters.');
        }
        setReason(inputValue);
    };

    return (
        <div className="delete-popup">
            <div className="confirm-delete-box rounded d-flex flex-column align-items-center p-4">
                <img src={deleteLogo} alt="delete logo" className="delete-box-logo img-fluid" />
                <div className="delete-box-title f-h f-bol">Are You Sure ?</div>
                <div className="delete-box-subtitle f-fh f-reg text-center">{content}</div>
                <div className="w-100">
                    <RemarksContainer
                        name="reason"
                        value={reason}
                        onChange={handleInputChange}
                        placeholder="Add Your Reason For Cancellation..."
                        labelClass="my-1 f-l f-sem-bol"
                        textClass={'f-l f-reg rounded input-shadow text-area-height'}
                    />
                    {error && <div className="error invalid f-12">{error}</div>}
                </div>
                <div className="delete-box-buttons d-flex justify-content-evenly w-100 my-3">
                    <Button
                        text={firstButton}
                        className={'delete-box-btn db-delete-btn f-16 f-sem-bol rounded'}
                        onClick={handleDelete}
                    />
                    <Button
                        text={secondButton}
                        className={'delete-box-btn db-cancel-btn f-16 f-sem-bol rounded'}
                        onClick={handleCancel}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConfirmCancelPopUp;
