import React from 'react';
import deleteLogo from '../../assets/images/delete-logo.svg';
import Button from '../CommonComponent/Button';

const ConfirmClosePopUp = ({ handleCancel, handleClose }) => {
    return (
        <div className="delete-popup">
            <div className="confirm-delete-box rounded d-flex flex-column align-items-center p-4">
                <img src={deleteLogo} alt="Close Ticket" className="delete-box-logo img-fluid my-1" />
                <div className="delete-box-title f-h f-bol my-1">Are You Sure ?</div>
                <div className="delete-box-subtitle f-fh f-sem-bol my-2 text-center">
                    Do you really want to close this ticket? <br />
                    This process cannot be undone.
                </div>
                <div className="delete-box-buttons d-flex justify-content-evenly w-100 my-3">
                    <Button
                        text={'Close'}
                        className={'delete-box-btn db-delete-btn f-sh f-sem-bol rounded-pill'}
                        onClick={handleClose}
                    />
                    <Button
                        text={'Cancel'}
                        className={'delete-box-btn db-cancel-btn f-sh f-sem-bol rounded-pill'}
                        onClick={handleCancel}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConfirmClosePopUp;
