import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { skillChecker } from '../../utils/helper';

const CreatableSelectComponent = ({
    name,
    value,
    onChange,
    labelClass,
    inputClass,
    errorClass,
    isDisabled,
    error,
    label,
    isRequired,
    options,
    isMulti,
    onInputChange,
    placeholder,
    onMenuOpen,
    inputValue,
}) => {
    const [iValue, setIValue] = useState(value);
    return (
        <>
            {label && (
                <label htmlFor={name} className={`c-label ${labelClass}`}>
                    {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
                </label>
            )}
            <div className={`position-relative`}>
                <CreatableSelect
                    className={`${inputClass} ${error ? 'error' : ''}`}
                    isMulti={isMulti}
                    classNamePrefix="select"
                    isSearchable={true}
                    options={options}
                    name={name}
                    isDisabled={isDisabled}
                    value={value}
                    onChange={onChange}
                    createOptionPosition="first"
                    onInputChange={onInputChange}
                    placeholder={placeholder}
                    maxMenuHeight={200}
                    onMenuOpen={onMenuOpen}
                />
            </div>
            {error && <div className={`invalid ${errorClass}`}>{error}</div>}
        </>
    );
};

export default CreatableSelectComponent;
