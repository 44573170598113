import React, { memo } from 'react';

const RangeDropdown = memo(({ handleEntriesLimit }) => {
    return (
        <div className="range-dropdown">
            <span className="f-14 f-sem-bol">Show</span>
            <select
                name="no-of-rows"
                id="no-of-rows"
                className="no-of-rows f-14 f-reg input-shadow"
                onChange={(e) => handleEntriesLimit(e.target.value)}
            >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <span className="f-14 f-sem-bol">Entries</span>
        </div>
    );
});

export default RangeDropdown;
