import React, { memo } from 'react';

const ToggleSwitch = memo(({ className, checked, onChange }) => {
    return (
        <label className={`toggle-switch ${className}`}>
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span className="switch" />
        </label>
    );
});
export default ToggleSwitch;
