import React, { lazy, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import errorImg from '../assets/images/RestrictedAccessImg.svg';
import { Button } from '../component/CommonComponent';

const RestrictedAccess = memo(() => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="error-page-wrapper w-100 h-100">
            <div className="error-content-wrapper d-flex flex-column align-items-center">
                <img src={errorImg} alt="error" className="error-img img-fluid mt-4" />
                <div className="error-title fs-4 f-bol">We are Sorry...</div>
                <div className="error-title fs-4 f-bol">
                    The page you&apos;re trying to access has restricted access.
                </div>
                <div className="error-subtitle  -danger fs-6 f-l f-bol mt-1">Please contact your administrator.</div>
                <Button
                    text={'Go Back'}
                    onClick={handleGoBack}
                    className={'home-btn f-l f-sem-bol cpp-btn mt-4 common-btn'}
                />
            </div>
        </div>
    );
});

export default RestrictedAccess;
