import { React, memo, useState, useLayoutEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const ContactInput = memo(
    ({
        label,
        labelClass,
        isRequired,
        formData,
        error,
        setForm,
        errorClass,
        form,
        propertyName,
        setError,
        disabled,
    }) => {
        const ref = useRef(null);

        const [width, setWidth] = useState(300);

        useLayoutEffect(() => {
            setWidth(ref.current.offsetWidth);
        }, []);

        let customStyles = {
            countryList: {
                width: `${width}px`,
            },
        };

        const handleCountryCode = (value, country, e, formattedValue) => {
            let number = value;
            let code = country.dialCode;
            let newNumber = number.slice(code.length, number.length);
            setForm((prev) => ({
                ...prev,
                [propertyName]: newNumber,
                countryCode: `+${code}`,
            }));
            setError((prev) => ({
                ...prev,
                [propertyName]: '',
            }));
        };

        return (
            <>
                <label className={labelClass}>
                    {label}
                    {isRequired ? (
                        <>
                            <span className="asterisk f-12 text-theme-red">*</span>
                        </>
                    ) : (
                        ''
                    )}
                </label>
                <div ref={ref}>
                    <PhoneInput
                        dropdownStyle={customStyles?.countryList}
                        name="phone"
                        country={'in'}
                        value={formData}
                        countryCodeEditable={false}
                        inputProps={{
                            name: 'phone',
                        }}
                        onChange={(value, country, e, formattedValue) =>
                            handleCountryCode(value, country, e, formattedValue)
                        }
                        containerClass={`d-flex w-100 flagDropdown f-l f-reg rounded bg-white input-shadow ${
                            error ? 'error' : ''
                        }`}
                        inputClass="w-100 f-12"
                        dropdownClass={`rounded`}
                        disabled={disabled}
                    />
                </div>
                {error && <div className={`invalid ${errorClass}`}>{error}</div>}
            </>
        );
    }
);
export default ContactInput;
