import React, { memo } from 'react';
import logo from '../../assets/images/incruiter-logo.svg';

const Invoice = memo(() => {
    return (
        <div className="invoice-container">
            <div className="invoice">
                <div className="invoice-header border border-primary">
                    <img src={logo} alt="logo" className="invoice-logo img-fluid" />
                    <div className="company-info border border-primary">
                        <h6 className="company-name f-10 f-bol">Left Brain Technlogies LLP</h6>
                        <p className="compnay-address f-8 f-reg">
                            91springboard, George Thangaiah Complex,
                            <br />
                            13, 80 Feet Rd, near Sir CV Raman Hospital,
                            <br />
                            Indiranagar, Bengaluru,
                            <br />
                            Karnataka 560038
                        </p>
                    </div>
                </div>
                <div className="invoice-main-content border border-primary">
                    <div className="company-details">
                        <div className="part-1">
                            <p className="f-8 f-sem-bol">Billed to</p>
                            <p className="f-8">Company Name</p>
                            <p className="f-8">Address Line 1 Address Line 2 State Country</p>
                        </div>
                        <div className="part-2">
                            <div>
                                <p className="f-8">Invoice number</p>
                                <p className="f-8">#INV123456</p>
                            </div>
                            <div>
                                <p className="f-8">Invoice number</p>
                                <p className="f-8">#INV123456</p>
                            </div>
                            <div>
                                <p className="f-8">Invoice number</p>
                                <p className="f-8">#INV123456</p>
                            </div>
                        </div>
                        <div className="part-3">
                            <div>
                                <p className="f-8">Invoice of (USD)</p>
                                <p className="f-8">₹ 49.00</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="invoice-footer border border-primary">
                    <h6 className="title f-8 f-bol">Terms & Conditions</h6>
                    <p className="terms-and-conditions f-8 f-reg">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec id turpis malesuada nibh. Faucibus
                        vitae, blandit aliquet scelerisque faucibus magna volutpat. Vitae aliquet maecenas purus sem.
                        Egestas pellentesque varius elit quisque placerat integer elit sed senectus.
                    </p>
                    <p className="additional-info f-8 f-sem-bol text-center">
                        646-888-6885 | www.abcdefg.com | email@gmail.com
                    </p>
                </div>
            </div>
        </div>
    );
});

export default Invoice;
