const BulkUploadDetails = ({ data, type, status }) => {
    return (
        <div className="bu-row-container">
            <div className="bu-row-content w-20 f-12">{data?.name}</div>
            <div className="bu-row-content w-30 f-12">{data?.email}</div>
            <div className="bu-row-content w-20 f-12">{data?.experience}</div>
            {type !== 'panelist' && <div className="bu-row-content resume-highlight w-25 f-12">{data?.resume}</div>}
            {status == 'success' ? (
                <div className="bu-row-content w-30 f-12">{data?.phone}</div>
            ) : (
                <ul className="bu-row-content w-30 f-12 text-start">
                    {data?.message && data?.message.map((itm, i) => <li key={i}>{itm.trim()}.</li>)}
                </ul>
            )}
        </div>
    );
};
export default BulkUploadDetails;
