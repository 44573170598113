import { TOKEN_VERIFY, TOKEN_VERIFY_SUCCESS, TOKEN_VERIFY_FAILURE } from './types';

export const tokenVerify = (payload) => ({
    type: TOKEN_VERIFY,
    payload,
});

export const tokenVerifySuccess = () => ({
    type: TOKEN_VERIFY_SUCCESS,
});

export const tokenVerifyFailure = () => ({
    type: TOKEN_VERIFY_FAILURE,
});
