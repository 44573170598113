import React from 'react';
// import { Button } from "../commonComponent";
import deleteIcon from '../../assets/images/delete-icon.svg';
import Button from './Button';
import CaptchaComponent from './Captcha';

const ConfirmDeletePopUp = ({
    icon,
    title,
    firstFunction,
    secondFunction,
    firstButtonName,
    secondButtonName,
    Message,
    captchaText,
    handleCaptchaInputChange,
    isCaptchaRequired,
}) => {
    return (
        <div className="delete-popup">
            <div className="confirm-delete-box rounded d-flex flex-column align-items-center p-4">
                <div className="title w-100 d-flex align-items-center justify-content-end">
                    <button type="button" className="btn-close" aria-label="Close" onClick={firstFunction}></button>
                </div>
                <div className="title w-100 d-flex align-items-center justify-content-start">
                    <img src={icon ? icon : deleteIcon} alt="icon" className="accept_icon" />
                </div>
                <div className="text_container mt-2 w-100">
                    <span className="title f-bol f-18">{title}</span>
                    <p className="text mt-1 f-reg f-14">{Message}</p>
                </div>
                {isCaptchaRequired && (
                    <CaptchaComponent captchaText={captchaText} handleCaptchaInputChange={handleCaptchaInputChange} />
                )}
                <div className="delete-box-buttons mt-2 d-flex justify-content-evenly w-100 mt-2">
                    <Button
                        text={firstButtonName}
                        className={`delete-box-btn rounded db-cancel-btn`}
                        onClick={firstFunction}
                    />
                    <Button
                        text={secondButtonName}
                        className={`delete-box-btn rounded db-delete-btn`}
                        onClick={secondFunction}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConfirmDeletePopUp;
