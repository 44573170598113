import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
    ADD_PANELIST,
    DELETE_PANELIST,
    EDIT_PANELIST,
    GET_PANELIST,
    UPDATE_PANELIST_STATUS,
    POST_BULK_PANELIST,
    NOTIFY_PANELIST,
    GET_ALL_PANELISTS,
} from '../action/types';
import INTERVIEW from '../../utils/api/interview';
import PANELIST from '../../utils/api/panelist';
import { Toast, localStorageJsonParseDecrypt } from '../../utils/helper';
import {
    addPanelistFailure,
    addPanelistSuccess,
    deletePanelistFailure,
    deletePanelistSuccess,
    getPanelist,
    getPanelistFailure,
    getPanelistSuccess,
    updatePanelistStatusFailure,
    updatePanelistStatusSuccess,
    updatePanelistSuccess,
    postBulkPanelistSuccess,
    postBulkPanelistFailure,
    notifyPanelistSuccess,
    notifyPanelistFailure,
    updatePanelistFailure,
    getAllPanelistSuccess,
    getAllPanelistFailure,
} from '../action/panelistActions';

function* addPanelistRequest(action) {
    try {
        const res = yield INTERVIEW.post(`interviews/panelist`, action?.payload?.data);
        if (res.status === 201) {
            yield put(addPanelistSuccess());
            yield toast.success(<Toast msg="Panelist added successfully." />);
            yield call(action?.payload?.callback, res);
        }
    } catch (e) {
        yield put(addPanelistFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getPanelistRequest(action) {
    let product = localStorageJsonParseDecrypt('productId')?.productId;
    try {
        const res = yield INTERVIEW.get(`interviews/panelistDropDown?product=${product}`);
        if (res.status === 200) {
            yield put(getPanelistSuccess(res.data.result));
            yield call(action?.payload?.callback);
        }
    } catch (e) {
        yield put(getPanelistFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getAllPanelistRequest(action) {
    let product = localStorageJsonParseDecrypt('productId')?.productId;
    const isPage = action.payload.page ? `&page=${action.payload.page}` : '';
    const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : '';
    const isSearch = action.payload.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';
    const isPageSize = action.payload.pageSize ? `&pageSize=${action.payload.pageSize}` : '';
    try {
        const res = yield INTERVIEW.get(
            `interviews/panelist?product=${product}${isPage}${isPageSize}${isSort}${isSearch}`
        );
        if (res.status === 200) {
            yield put(getAllPanelistSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getAllPanelistFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* updatePanelistRequest(action) {
    try {
        const res = yield INTERVIEW.post(`interviews/panelist/${action?.payload?.id}`, action?.payload?.data);
        if (res.status === 201) {
            yield put(updatePanelistSuccess());
            yield toast.success(<Toast msg="Panelist updated successfully." />);
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(updatePanelistFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* deletePanelistRequest(action) {
    try {
        const res = yield INTERVIEW.delete(`interviews/panelist/${action.payload.id}`);
        if (res.status === 204) {
            yield put(getPanelist());
            yield toast.success(<Toast msg={'Panelist deleted successfully.'} />);
            yield put(deletePanelistSuccess());
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(deletePanelistFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* updatePanelistStatusRequest(action) {
    try {
        const res = yield INTERVIEW.post(`interviews/panelist/status/${action?.payload?.id}`, action?.payload?.status);
        if (res.status === 201) {
            yield call(action.payload.callback);
            // yield put(getPanelist({ page: 1, pageSize: 10 }));
            yield toast.success(
                <Toast msg={`Panelist ${action?.payload?.status?.status === 'active' ? 'Activated' : 'Deactivated'}`} />
            );
            yield put(updatePanelistStatusSuccess());
        }
    } catch (e) {
        yield put(updatePanelistStatusFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* postBulkPanelistRequest(action) {
    const formData = new FormData();
    formData.append('panelist', action.payload.panelist);
    formData.append('product', action.payload.productId);
    formData.append('confirm', action.payload.confirm);
    try {
        const res = yield INTERVIEW.post(`interviews/addBulkPanelist`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201 || res.status === 200) {
            if (action?.payload?.confirm === 0) {
                if (res?.data?.result?.data?.length) {
                    yield put(postBulkPanelistSuccess(res.data.result));
                    yield call(action.payload.callback, true);
                } else {
                    yield put(postBulkPanelistFailure());
                    yield toast.error(<Toast msg="The uploaded file is empty." />);
                    yield call(action.payload.callback, false);
                }
            } else {
                if (res?.data?.result?.data?.success?.length) {
                    yield put(postBulkPanelistSuccess(res.data.result));
                    yield call(action.payload.callback, true);
                    yield toast.success(<Toast msg="Bulk Panelist created successfully." />);
                }
            }
        }
    } catch (e) {
        if (e?.response?.status === 400) {
            yield put(postBulkPanelistFailure(e?.response?.data?.message));
        } else if (!e?.response?.status) {
            yield put(postBulkPanelistFailure());
            toast.error(<Toast msg="Please refresh the page and try again!" />, {
                toastId: 'Please refresh the page and try again!',
            });
        } else {
            yield put(postBulkPanelistFailure());
            yield e?.response?.data?.message &&
                e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
                toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
        }
    }
}

function* notifyPanelistRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviews/notifyPanelist/${action.payload.panelistId}`);
        if (res.status) {
            yield put(notifyPanelistSuccess(res.data.result));
            yield call(action.payload.callback);
            yield toast.success(<Toast msg="Panelist notified successfully." />);
        }
    } catch (e) {
        yield put(notifyPanelistFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

export function* watchPostBulkPanelistRequest() {
    yield takeEvery(POST_BULK_PANELIST, postBulkPanelistRequest);
}

export function* watchNotifyPanelistRequest() {
    yield takeEvery(NOTIFY_PANELIST, notifyPanelistRequest);
}

export function* watchAddPanelistRequest() {
    yield takeEvery(ADD_PANELIST, addPanelistRequest);
}

export function* watchGetPanelistRequest() {
    yield takeEvery(GET_PANELIST, getPanelistRequest);
}
export function* watchGetAllPanelistRequest() {
    yield takeEvery(GET_ALL_PANELISTS, getAllPanelistRequest);
}
export function* watchUpdatePanelistRequest() {
    yield takeEvery(EDIT_PANELIST, updatePanelistRequest);
}

export function* watchDeletePanelistRequest() {
    yield takeEvery(DELETE_PANELIST, deletePanelistRequest);
}

export function* watchUpdatePanelistStatusRequest() {
    yield takeEvery(UPDATE_PANELIST_STATUS, updatePanelistStatusRequest);
}

export default function* rootSaga() {
    yield all([
        watchPostBulkPanelistRequest(),
        watchNotifyPanelistRequest(),
        watchAddPanelistRequest(),
        watchGetPanelistRequest(),
        watchUpdatePanelistRequest(),
        watchUpdatePanelistStatusRequest(),
        watchDeletePanelistRequest(),
        watchGetAllPanelistRequest(),
    ]);
}
