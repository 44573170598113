import React, { memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const BreadCrumbs = memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb f-l f-bol">
                <li
                    className="breadcrumb-item"
                    onClick={() => {
                        navigate('/position');
                    }}
                    style={
                        location?.pathname === '/create-panelist' || location?.pathname === '/add-applicant'
                            ? { color: '#007BFF' }
                            : null
                    }
                >
                    Create Position
                </li>
                {(location?.pathname === '/create-panelist' || location?.pathname === '/add-applicant') && (
                    <li
                        onClick={() => {
                            navigate('/create-panelist');
                        }}
                        className="breadcrumb-item"
                        style={location?.pathname === '/add-applicant' ? { color: '#007BFF' } : null}
                    >
                        Add Panelist
                    </li>
                )}
                {location?.pathname === '/add-applicant' && (
                    <li className="breadcrumb-item" aria-current="page">
                        Post Interview
                    </li>
                )}
            </ol>
        </nav>
    );
});

export default BreadCrumbs;
