import { TOKEN_VERIFY, TOKEN_VERIFY_SUCCESS, TOKEN_VERIFY_FAILURE, CLEAR_SIGNUP_AUTH_ERRORS } from '../action/types';

const INIT_STATE = {
    loading: false,
    authErrorMessage: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOKEN_VERIFY:
            return { ...state, loading: true };
        case TOKEN_VERIFY_SUCCESS:
            return { ...state, loading: false };
        case TOKEN_VERIFY_FAILURE:
            return { ...state, loading: false };
        case CLEAR_SIGNUP_AUTH_ERRORS:
            return { ...state, loading: false, authErrorMessage: null };
        default:
            return state;
    }
};
