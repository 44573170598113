import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { positionNameChecker } from '../../utils/helper';

const JobTitleSelect = ({
    name,
    value,
    onChange,
    labelClass,
    inputClass,
    errorClass,
    isDisabled,
    error,
    label,
    isRequired,
    options,
    isMulti,
    placeholder,
    setSelectedPositionTitle,
}) => {
    const [Name, setName] = useState('');
    return (
        <>
            {label && (
                <label htmlFor={name} className={`c-label ${labelClass}`}>
                    {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
                </label>
            )}
            <div className={`position-relative`}>
                <CreatableSelect
                    className={`${inputClass} ${error ? 'error' : ''}`}
                    isMulti={isMulti}
                    classNamePrefix="select"
                    isSearchable={true}
                    options={options}
                    name={name}
                    isDisabled={isDisabled}
                    value={value}
                    onChange={onChange}
                    createOptionPosition="first"
                    placeholder={placeholder}
                    maxMenuHeight={180}
                    onMenuOpen={() => {
                        setName(value?.value);
                    }}
                    inputValue={Name}
                    onInputChange={(inputValue, action) => {
                        if (action?.action !== 'input-blur' && action?.action !== 'menu-close') {
                            if (inputValue === '') {
                                setSelectedPositionTitle(null);
                            }
                            setName(positionNameChecker(inputValue));
                        }
                    }}
                />
            </div>
            {error && <div className={`invalid ${errorClass}`}>{error}</div>}
        </>
    );
};

export default JobTitleSelect;
