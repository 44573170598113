import {
    GET_APPLICANT,
    GET_APPLICANT_SUCCESS,
    GET_APPLICANT_FAILURE,
    DELETE_APPLICANT,
    DELETE_APPLICANT_SUCCESS,
    DELETE_APPLICANT_FAILURE,
    UPDATE_APPLICANT,
    UPDATE_APPLICANT_SUCCESS,
    UPDATE_APPLICANT_FAILURE,
    APPLICANT_SENT_INVITE,
    APPLICANT_SENT_INVITE_SUCCESS,
    APPLICANT_SENT_INVITE_FAILURE,
} from '../action/types';
const INIT_STATE = {
    loading: false,
    lists: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APPLICANT:
            return { ...state, loading: true };
        case GET_APPLICANT_SUCCESS:
            return {
                ...state,
                loading: false,
                lists: action.payload,
            };
        case GET_APPLICANT_FAILURE:
            return { ...state, loading: false };
        case DELETE_APPLICANT:
            return { ...state, loading: true };
        case DELETE_APPLICANT_SUCCESS:
            return { ...state, loading: false };
        case DELETE_APPLICANT_FAILURE:
            return { ...state, loading: false };
        case UPDATE_APPLICANT:
            return { ...state, loading: true };
        case UPDATE_APPLICANT_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_APPLICANT_FAILURE:
            return { ...state, loading: false };
        case APPLICANT_SENT_INVITE:
            return { ...state, loading: true };
        case APPLICANT_SENT_INVITE_SUCCESS:
            return { ...state, loading: false };
        case APPLICANT_SENT_INVITE_FAILURE:
            return { ...state, loading: false };

        default:
            return state;
    }
};
