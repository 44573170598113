import React, { memo } from 'react';
import file from '../../assets/images/file.svg';
import { capitalizeAll } from '../../utils/helper';
// import xmark from "../../assets/images/website-images/x-mark.svg";

const FileInputComponent = memo(
    ({
        name,
        onChange,
        placeholder,
        type,
        labelClass,
        inputClass,
        errorClass,
        error,
        label,
        isRequired,
        disabled,
        ref,
    }) => {
        return (
            <>
                {label && (
                    <label htmlFor={name} className={`c-label ${labelClass}`}>
                        {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
                    </label>
                )}
                <div
                    className={`position-relative file-input bg-white d-flex align-items-center justify-content-end rounded`}
                >
                    <input
                        id="fileInput"
                        type={type}
                        className={`${error ? 'error' : ''} ${inputClass} position-relative real-file-input`}
                        name={name}
                        placeholder={capitalizeAll(placeholder)}
                        onChange={
                            onChange
                            // if (e.target.files[0] !== 0) {
                            //   setEmpty(!empty);
                            // } else {
                            //   setEmpty(empty);
                            // }
                        }
                        disabled={disabled}
                        ref={ref}
                    />
                    {/* {empty && ( */}
                    <img src={file} alt="file" className="file h-75 position-absolute img-fluid" />
                    {/* )}
          {!empty && (
            <img
              src={xmark}
              alt="close"
              className="img-fluid close-mark position-absolute"
              onClick={() => {
                document.getElementById("fileInput").value = null;
                setEmpty(!empty);
              }}
            />
          )} */}
                </div>
                <span className="upload-type f-ph f-reg">*supported file types are .pdf, .docx, .doc</span>
                {error && <div className={`invalid ${errorClass}`}>{error}</div>}
            </>
        );
    }
);

export default FileInputComponent;
