import { combineReducers } from 'redux';

import Login from './loginReducer';
import Signup from './signupReducer';
import MyProfile from './myProfileReducer';
import Position from './positionReducer';
import Product from './productReducer';
import Interview from './interviewReducer';
import RolesAndSubUser from './rolesAndSubUserReducer';
import Support from './supportReducer';
import Dashboard from './dashboardReducer';
import Panelist from './panelistReducer';
import CustomField from './customFieldReducer';
import Applicant from './applicantReducer';
const appReducer = combineReducers({
    Login,
    Signup,
    MyProfile,
    Position,
    Product,
    Interview,
    RolesAndSubUser,
    Support,
    Dashboard,
    Panelist,
    CustomField,
    Applicant,
});

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;
