import React, { memo } from 'react';
import back from '../../assets/images/back-arrow.svg';

const BackButton = memo(({ onClick }) => {
    return (
        <div
            className="back-btn-container d-flex align-items-center justify-content-center f-sem-bol f-l"
            onClick={onClick}
        >
            <img src={back} alt="back-arrow" className="" />
            <span className="back">Back</span>
        </div>
    );
});

export default BackButton;
