import axios from 'axios';
import { redirectPermission, mainWebsite, generateUrl } from '../helper';

const panelist = axios.create({
    baseURL: generateUrl('interview_backend'),
});

panelist.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

panelist.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default panelist;
