import React, { memo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const MenuButton = memo(({ img, options, fromPosition }) => {
    const [visible, setVisible] = useState(false);

    return (
        <Dropdown show={visible} onToggle={(isOpen) => setVisible(isOpen)}>
            <Dropdown.Toggle
                variant="primary"
                id={`${fromPosition ? 'dropdown-basic-transparent' : 'dropdown-basic'}`}
                title="More Options"
            >
                <img src={img} alt="threeDot" className="threedot-img" title="More Options" />
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="dropdown-menu shadow-6 threedot-menu rounded"
                popperConfig={{
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                altAxis: true,
                                tether: false,
                            },
                        },
                    ],
                }}
                rootClose
                renderOnMount
            >
                {options &&
                    options?.length > 0 &&
                    options?.map((option, index) => (
                        <React.Fragment key={index}>
                            <Dropdown.Item
                                disabled={option?.disabled}
                                key={index}
                                onClick={() => option?.action()}
                                className={`threedot-menu-content d-flex align-items-center f-l f-sem-bol m-1 ps-2 rounded ${
                                    option?.disabled ? 'threedot-menu-content-disabled' : ''
                                }`}
                            >
                                {option?.name}
                            </Dropdown.Item>
                        </React.Fragment>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    );
});

export default MenuButton;
