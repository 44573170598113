import React, { memo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import RoutesFile from './RoutesFile';
import MainPage from '../container/MainPage';
import NotFound from '../container/404NotFound';
const RouteList = memo(() => {
    const [loginStatus, setLoginStatus] = useState(true);
    return (
        <Routes>
            {RoutesFile.map((itm, key) => (
                <Route
                    key={key}
                    path={itm.path}
                    exact={itm.exact}
                    element={
                        itm?.loginRequired ? (
                            <MainPage
                                name={itm.name}
                                Component={itm.component}
                                pvt={itm.private}
                                mainResource={itm.mainResource}
                                resource={itm.resource}
                                permission={itm.permission}
                                action={itm.action}
                                setLoginStatus={setLoginStatus}
                                loginStatus={loginStatus}
                            />
                        ) : (
                            <itm.component />
                        )
                    }
                />
            ))}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
});

export default RouteList;
