import React, { memo, useState, useRef, useEffect } from 'react';
import { useOutsideAlerter } from '../../utils/helper';
import filter from '../../assets/images/filter.svg';
import { STATUSES } from '../../utils/constant';
import moment from 'moment';

const ApplicantFilterBtn = memo(
    ({ filterForm, setFilterForm, initialFilter, nameText, filterStatus, setFilterStatus }) => {
        const [showFilter, setShowFilter] = useState(false);
        const [activeQuickFilter, setActiveQuickFilter] = useState('');
        const ref = useRef();

        useOutsideAlerter(ref, () => setShowFilter(false));

        useEffect(() => {
            if ((!filterForm.filter?.qFrom || !filterForm.filter?.qTo) && activeQuickFilter) setActiveQuickFilter('');
        }, [filterForm]);

        const quickFilters = [
            {
                label: 'Last 24 hours',
                value: JSON.stringify({
                    qFrom: moment().subtract(1, 'days').toISOString(),
                    qTo: moment().toISOString(),
                }),
            },
            {
                label: 'Last 3 days',
                value: JSON.stringify({
                    qFrom: moment().subtract(3, 'days').toISOString(),
                    qTo: moment().toISOString(),
                }),
            },
            {
                label: 'Last 7 days',
                value: JSON.stringify({
                    qFrom: moment().subtract(7, 'days').toISOString(),
                    qTo: moment().toISOString(),
                }),
            },
        ];

        const orderOptions = [
            { label: 'Newest to Oldest', value: '_id desc' },
            { label: 'Oldest to Newest', value: '_id asc' },
            {
                label: nameText ? `${nameText} (A - Z)` : 'Name (A - Z)',
                value: 'positionTitle asc',
            },
        ];

        const getLabelOptions = () => {
            return [
                { label: STATUSES.PENDING, value: STATUSES.PENDING },
                { label: STATUSES.INCOMPLETE_DATA, value: STATUSES.INCOMPLETE_DATA },
                { label: STATUSES.INVITE_SENT, value: STATUSES.INVITE_SENT },
                { label: STATUSES.COMPLETED, value: STATUSES.COMPLETED },
                { label: STATUSES.CANCELED, value: STATUSES.CANCELED },
            ];
        };

        const handleShowFilter = () => {
            setShowFilter(!showFilter);
        };

        const handleFilterChange = (value, inputName) => {
            setFilterForm((prev) => ({
                ...prev,
                page: 1,
                filter: {
                    ...prev?.filter,
                    [inputName]: value,
                    ...(inputName === 'from' && { to: '' }),
                },
            }));
        };

        const handleFilterStatusChange = (value) => {
            const index = filterStatus.indexOf(value);
            let finalStatus;
            if (index === -1) {
                finalStatus = [...filterStatus, value];
                setFilterStatus([...filterStatus, value]);
            } else {
                const newStatus = [...filterStatus];
                newStatus.splice(index, 1);
                finalStatus = newStatus;
                setFilterStatus(newStatus);
            }
            setFilterForm((prev) => ({
                ...prev,
                page: 1,
                filter: {
                    ...prev?.filter,
                    status: finalStatus.join(','),
                },
            }));
        };

        const isAnyFieldNotEmpty = () => {
            for (const key in filterForm?.filter) {
                if (filterForm.filter[key] && filterForm.filter[key].trim() !== '') {
                    return true;
                }
            }
            return false;
        };

        const handleClearFilter = () => {
            if (isAnyFieldNotEmpty()) {
                setFilterForm({
                    ...initialFilter,
                    pageType: filterForm?.pageType,
                    status: filterForm?.status,
                });
                setShowFilter(false);
                setFilterStatus([]);
                setActiveQuickFilter('');
            }
        };

        const handleCheckboxKeyDown = (e, value) => {
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleFilterStatusChange(value);
            }
        };

        const handleOrder_byKeyDown = (e, value) => {
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleFilterChange(value, 'sort');
            }
        };

        const handleQuickFilterChange = (e, label) => {
            const { value } = e.target;
            setActiveQuickFilter(label);
            setFilterForm((prevFilterForm) => ({
                ...prevFilterForm,
                filter: {
                    ...prevFilterForm.filter,
                    ...JSON.parse(value),
                },
            }));
        };

        const handleQuickFilterKeyDown = (e, label) => {
            if (e.key === 'Enter' || e.key === '') {
                e.preventDefault();
                handleQuickFilterChange(e, label);
            }
        };

        const minDate = moment(filterForm?.filter?.from).format('YYYY-MM-DD') || '';
        const maxDate = moment().format('YYYY-MM-DD');

        return (
            <div className="filter-btn-container position-relative ms-1">
                <button className="filter-btn d-center" onClick={handleShowFilter}>
                    <img src={filter} alt="filter-btn" className="filter-img img-fluid" title="Filter" />
                </button>
                {showFilter && (
                    <div
                        className="position-absolute filter-btn-popup input-shadow rounded f-reg"
                        ref={ref}
                        style={{ width: '13em' }}
                    >
                        <div className="f-med title f-l">Quick Filter</div>
                        {quickFilters.map((option, index) => (
                            <label htmlFor={option.value} key={index} className="f-l d-flex align-items-center">
                                <input
                                    type="radio"
                                    value={option.value}
                                    checked={activeQuickFilter === option.label}
                                    onChange={(e) => handleQuickFilterChange(e, option.label)}
                                    tabIndex={0} // tabIndex for keyboard accessibility
                                    onKeyDown={(e) => handleQuickFilterKeyDown(e, option.label)}
                                />
                                <span>{option.label}</span>
                            </label>
                        ))}
                        <div className="date-container f-reg">
                            <label htmlFor="" className="f-l">
                                <span className="f-sem-bol"> Select Applied Date</span>
                                <span className="invited-filter">
                                    <div>
                                        <p className="to-from-font">From</p>
                                        <input
                                            type="date"
                                            className="invited-date-filter"
                                            value={filterForm?.filter?.from || ''}
                                            onChange={(e) => handleFilterChange(e.target.value, 'from')}
                                            max={maxDate}
                                            placeholder="From Date"
                                        />
                                    </div>
                                    <div>
                                        <p className="to-from-font">To</p>
                                        <input
                                            type="date"
                                            className="invited-date-filter"
                                            value={filterForm?.filter?.to ? filterForm?.filter?.to : ''}
                                            onChange={(e) => handleFilterChange(e.target.value, 'to')}
                                            min={minDate}
                                            max={maxDate}
                                            placeholder="To Date"
                                        />
                                    </div>
                                </span>
                            </label>
                        </div>

                        <div className="f-med title f-l">Status</div>
                        {getLabelOptions().map((option, index) => (
                            <label htmlFor={option.value} key={index} className="f-l d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={filterStatus.includes(option.value)}
                                    onChange={() => handleFilterStatusChange(option.value)}
                                    tabIndex={0} // tabIndex for keyboard accessibility
                                    onKeyDown={(e) => handleCheckboxKeyDown(e, option.value)}
                                />
                                <span>{option.label}</span>
                            </label>
                        ))}
                        <div className="f-med title f-l">Order By</div>
                        {orderOptions.map((option, index) => (
                            <label htmlFor={option.value} key={index} className="f-l d-flex align-items-center">
                                <input
                                    type="radio"
                                    value={option.value}
                                    checked={filterForm?.filter?.sort === option.value}
                                    onChange={(e) => handleFilterChange(e.target.value, 'sort')}
                                    tabIndex={0} // tabIndex for keyboard accessibility
                                    onKeyDown={(e) => handleOrder_byKeyDown(e, option.value)}
                                />
                                <span>{option.label}</span>
                            </label>
                        ))}
                        <button
                            className={`filter-clear-btn d-flex align-items-center justify-content-center ${
                                isAnyFieldNotEmpty() ? '' : 'disabled'
                            }`}
                            onClick={handleClearFilter}
                        >
                            Clear Filter
                        </button>
                    </div>
                )}
            </div>
        );
    }
);

export default ApplicantFilterBtn;
