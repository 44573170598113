import React from 'react';
import { Toast } from '../../utils/helper';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/warning-logo.svg';
import infoLogo from '../../assets/images/info-logo.svg';
import { useState } from 'react';
import { updateInterviewStatus } from '../../redux/action';
import { toast } from 'react-toastify';
import Button from '../CommonComponent/Button';
import CloseButton from '../CommonComponent/CloseButton';

const RescheduleErrorPopup = ({ InterviewData, handleClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [confirmCancel, setConfirmCancel] = useState(false);
    const handleCancel = () => {
        if (InterviewData?._id) {
            dispatch(
                updateInterviewStatus({
                    id: InterviewData?._id,
                    status: 'cancelled',
                    reason: 'Interview reschedule from a different account',
                })
            );
            navigate('/add-applicant', {
                state: { data: InterviewData, edit: 0 },
            });
            handleClose();
        } else {
            toast.error(<Toast msg="Interview can't be cancelled" />, { toastId: "Interview can't be cancelled" });
        }
    };
    return (
        <div className="delete-popup">
            {/* {loading && <Loader />} */}
            <div className="common-popup-box rounded d-flex flex-column align-items-center p-4">
                <div className="d-flex justify-content-between w-100 mb-2">
                    <img src={logo} alt="delete logo" className="box-logo img-fluid" />
                    <CloseButton onClick={handleClose} />
                </div>
                <div className="f-18 f-bol text-start w-100">
                    {confirmCancel
                        ? 'Cancel Interview'
                        : 'You can not reschedule this interview as it was scheduled by another account.'}
                </div>
                <div className="f-14 text-start w-100 f-reg">
                    {confirmCancel
                        ? 'Do you really want to cancel this interview?'
                        : 'You can cancel this interview and create a new one.'}
                </div>
                {!confirmCancel && (
                    <>
                        <div className="f-10 text-start w-100 f-reg d-flex align-items-center">
                            <img src={infoLogo} alt="info logo" className="info-logo" />
                            <span className="mx-1">For further information you can contact</span>
                            <span className="support-link f-sem-bol" onClick={() => navigate('/support')}>
                                support
                            </span>
                        </div>
                    </>
                )}
                <div className="delete-box-buttons d-flex justify-content-between w-100 mt-3">
                    <Button
                        text={'Close'}
                        className={'delete-box-btn db-cancel-btn f-16 f-sem-bol rounded'}
                        onClick={handleClose}
                    />
                    <Button
                        text={'Cancel Interview'}
                        className={'delete-box-btn db-cancel-interview-btn f-16 f-sem-bol rounded'}
                        onClick={confirmCancel ? handleCancel : () => setConfirmCancel(true)}
                    />
                </div>
            </div>
        </div>
    );
};

export default RescheduleErrorPopup;
